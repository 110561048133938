import { Button } from '@hausgold/designsystem';
import { map } from 'lodash-es';
import React, { Fragment, useContext } from 'react';
import { ApiContext, WizardContext } from 'fw-utils/contexts';
import FluidMaxContainer from '../components/FluidMaxContainer';
import WizardCard from '../components/WizardCard';
import {
  getHeadingText,
  getLineIcon,
  getLineIconSVG,
  getLineKeys,
  getLineTitle,
  getStepKeys,
} from '../services/models/dialog';

const Start = () => {
  const { data } = useContext(ApiContext);
  const {
    position,
    setCurrentLine,
    setCurrentStep,
    components: {
      WizardHeading,
      StartLayoutContainer,
      CustomNavigationButtonsStart,
      ChoiceOptionColContainer,
      ChoiceOptionRowContainer,
      CustomWizardCard,
    },
  } = useContext(WizardContext);

  const lines = getLineKeys(data);

  return (
    <Fragment>
      <WizardHeading>{getHeadingText(data)}</WizardHeading>
      <FluidMaxContainer>
        <StartLayoutContainer>
          <ChoiceOptionRowContainer>
            {map(lines, (key) => (
              <ChoiceOptionColContainer
                key={key}
                position={position}
                context="start"
              >
                <Button
                  as="a"
                  variant="unstyled"
                  role="link"
                  onClick={() => {
                    const firstStep = getStepKeys(data, key)[0];
                    const chosenLine = key;

                    // Track user progress
                    window?.analytics?.track(
                      `${chosenLine}-clickto_step${1}_${firstStep}`,
                      {
                        label: getLineTitle(data, key),
                        category: 'formwizard',
                      }
                    );

                    // Set chosen line
                    setCurrentLine(chosenLine);

                    // Set current step
                    setCurrentStep(firstStep);
                  }}
                >
                  {CustomWizardCard ? (
                    <CustomWizardCard
                      iconId={getLineIcon(data, key)}
                      iconSvg={getLineIconSVG(data, key)}
                      title={getLineTitle(data, key)}
                    />
                  ) : (
                    <WizardCard
                      iconId={getLineIcon(data, key)}
                      iconSvg={getLineIconSVG(data, key)}
                      title={getLineTitle(data, key)}
                    />
                  )}
                </Button>
              </ChoiceOptionColContainer>
            ))}
          </ChoiceOptionRowContainer>
          {CustomNavigationButtonsStart && <CustomNavigationButtonsStart />}
        </StartLayoutContainer>
      </FluidMaxContainer>
    </Fragment>
  );
};

export default Start;

import buildRedirectUrl from 'fw-utils/buildRedirectUrl';
import qs from 'qs';
import { v4 as uuidv4 } from 'uuid';
import ApiClient from '../../lib/apiV2';

// The instance of Formwizard client.
let instance = null;

class FormwizardApi extends ApiClient {
  /**
   * @param {object} appsignal
   */
  constructor({ appsignal, isLocal, ...config }) {
    super(isLocal, config);

    this.appsignal = appsignal;
  }

  /**
   * Gets the dialog data for the specified ID
   *
   * @param id
   */
  getDialog = ({ id }) =>
    this.request({
      url: 'dialogs/{id}.json',
      method: 'get',
      data: { id },
    });

  /**
   * Creates a lead
   *
   * @param id
   *
   * @param {string} authenticity_token
   * @param {string} dialog_line
   * @param {string} dialog_token
   * @param {string} dialog_version
   * @param {string} lead_id_code
   * @param {string} recruiter_id
   *
   * @param {string} email
   * @param {string} phone
   * @param {string} region
   * @param {string} form_of_address
   * @param {string} firstname
   * @param {string} lastname
   *
   * @param {object} stepData
   */
  createLead = ({
    id,
    authenticity_token,
    dialog_line,
    dialog_token,
    dialog_version,
    email,
    lead_id_code,
    recruiter_id,
    phone,
    region,
    form_of_address,
    firstname,
    lastname,
    stepData,
    ...rest
  }) =>
    this.request({
      url: 'leads/{id}',
      method: 'post',
      data: {
        id,
        authenticity_token,
        dialog_line,
        dialog_token,
        dialog_version,
        email,
        lead_id_code,
        recruiter_id,
        phone: phone?.trim(),
        region,
        form_of_address,
        firstname: firstname?.trim(),
        lastname: lastname?.trim(),
        ...stepData,
        ...rest,
      },
    });

  /**
   * Process the lead data and send a create lead request to the API.
   */
  processLead = ({
    // Attributes
    config,
    currentLine,
    data,
    dialogId,
    leadId,
    recruiterId,
    stepData,
    values,

    // Getter
    getAuthenticityToken,
    getVersion,

    // Setter
    setIsLoading,
    setUserData,
  }) => {
    setIsLoading(true);
    setUserData(values);

    return this.createLead({
      id: dialogId,
      authenticity_token: getAuthenticityToken(data),
      dialog_token: dialogId,
      dialog_line: currentLine,
      dialog_version: getVersion(data),
      lead_id_code: leadId,
      recruiter_id: recruiterId,
      email: values.email,
      phone: values.phone,
      form_of_address: values.gender,
      firstname: values.firstName,
      lastname: values.lastName,
      stepData,
      metadata: {
        wizard_session_id: this.metadata.sessionId,
        wizard_variant: this.metadata.variant,
        wizard_referrer: this.metadata.referrer,
      },
    })
      .then((response) => {
        const {
          leadid,
          object_data: { real_estate_type: type },
        } = response?.data;

        return {
          createdLeadId: leadid,
          redirectUrl: buildRedirectUrl({
            ...values,
            line: currentLine,
            leadId: leadid,
            type,
            config,
          }),
          response,
        };
      })
      .catch((error) => {
        setIsLoading(false);

        if (this.appsignal) {
          this.appsignal.send(
            this.appsignal.createSpan((span) => {
              span.setAction('Formwizard submission failed');
              span.setError(error);
              span.setTags({
                dialogId,
              });
            })
          );
        }
      });
  };
}

// Set the Formwizard client instance.
const setInstance = (config, metadata, appsignal = null, isLocal = null) => {
  instance = new FormwizardApi({
    appsignal,
    isLocal,
    baseUrl: config.api.urls.wizard.http,
  });
  instance.metadata = metadata || {};
  instance.metadata.sessionId = uuidv4();
  instance.metadata.referrer =
    qs.parse(window.location.search.slice(1)).origin || window.location.href;
};

// Expose the Formwizard client.
const formwizardApi = () => instance;

export default formwizardApi;
export { setInstance };

import React from 'react';
import Layout from './Layout';
import Formwizard from '../../../wizard';
import Footer from './Footer';
import CustomNavigationButtons from './CustomNavigationButtons';
import UserDataStep from './UserDataStep';

/**
 * Adds a formwizard variant called raffle
 */
const Raffle = React.forwardRef(({ ...props }, ref) => (
  <Layout ref={ref}>
    <Formwizard
      components={{
        UserDataStep,
        CustomNavigationButtons,
      }}
      {...props}
    />
    <Footer
      mt={3}
      // Compensate Layout padding of 8 so the grayed Footer reaches left and right end.
      mx={-8}
    />
  </Layout>
));

export default Raffle;

import React from 'react';
import Formwizard from '../../../wizard';
import CustomNavigationButtons from './CustomNavigationButtons';
import CustomNavigationButtonsStart from './CustomNavigationButtonsStart';
import Layout from './Layout';
import ProgressBarCTA from './ProgressBarCTA';

const CTA = React.forwardRef(({ ...props }, ref) => (
  <Layout ref={ref}>
    <Formwizard
      components={{
        ProgressBarCTA,
        CustomNavigationButtons,
        CustomNavigationButtonsStart,
      }}
      {...props}
    />
  </Layout>
));

export default CTA;

import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@hausgold/designsystem';

const NavigationButtonsLayoutContainer = ({ children, ...props }) => (
  <Flex {...props}>{children}</Flex>
);

NavigationButtonsLayoutContainer.propTypes = {
  children: PropTypes.node,
};

NavigationButtonsLayoutContainer.defaultProps = {
  children: null,
};

export default NavigationButtonsLayoutContainer;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  FIELD_TYPE_ADDRESS,
  FIELD_TYPE_CHOICE,
  FIELD_TYPE_NUMERICAL,
  FIELD_TYPE_POSTAL,
  FIELD_TYPE_TEXT,
} from 'fw-utils/CONSTANTS';
import { ApiContext, WizardContext } from 'fw-utils/contexts';
import Default from './Default';
import Choice from './Choice';
import Address from './Address';
import Number from './Number';
import Slider from './Slider';
import { getStep } from '../../../services/models/dialog';

const Renderer = ({ stepKey, lineKey }) => {
  const { data } = useContext(ApiContext);
  const { currentStep: currentStepKey, currentLine } = useContext(
    WizardContext
  );

  const { options, type } = getStep(data, currentLine, currentStepKey);

  const {
    mandatory,
    placeholder,
    street_placeholder: placeholderStreet,
    zipcode_placeholder: placeholderZip,
    city_placeholder: placeholderCity,
    step_size: stepSize,
    default: defaultValue,
    min,
    max,
    unit,
    ...restOptions
  } = options;

  switch (type) {
    case FIELD_TYPE_CHOICE:
      return (
        <Choice
          options={restOptions}
          mandatory={mandatory}
          stepKey={stepKey}
          lineKey={lineKey}
        />
      );
    case FIELD_TYPE_ADDRESS:
      return (
        <Address
          options={restOptions}
          mandatory={mandatory}
          placeholderStreet={placeholderStreet}
          placeholderZip={placeholderZip}
          placeholderCity={placeholderCity}
          stepKey={stepKey}
          lineKey={lineKey}
        />
      );
    case 'slider':
      return (
        <Slider
          options={restOptions}
          mandatory={mandatory}
          stepKey={stepKey}
          lineKey={lineKey}
          unit={unit}
          placeholder={placeholder}
          min={min}
          max={max}
          defaultValue={defaultValue}
          stepSize={stepSize}
        />
      );
    case FIELD_TYPE_NUMERICAL:
    case FIELD_TYPE_POSTAL:
      return (
        <Number
          options={restOptions}
          mandatory={mandatory}
          stepKey={stepKey}
          lineKey={lineKey}
          type={type}
          unit={unit}
          placeholder={placeholder}
        />
      );
    case FIELD_TYPE_TEXT:
      // Sadly, 'text' - in the eye of the backend - can also be number fields,
      // so that's a bit confusing.
      return (
        <Default
          type={min || max ? 'number' : 'text'}
          name={stepKey}
          {...restOptions}
          stepKey={stepKey}
          lineKey={lineKey}
        />
      );
    default:
      return (
        <Default
          type="text"
          name={stepKey}
          {...restOptions}
          stepKey={stepKey}
          lineKey={lineKey}
        />
      );
  }
};

Renderer.propTypes = {
  stepKey: PropTypes.string.isRequired,
  lineKey: PropTypes.string.isRequired,
};

export default Renderer;

import { HeadingH1 } from '@hausgold/designsystem';
import PropTypes from 'prop-types';
import React from 'react';

const WizardHeading = ({ children, ...props }) => (
  <HeadingH1 as="div" textAlign="center" mb={8} {...props}>
    {children}
  </HeadingH1>
);

WizardHeading.propTypes = {
  children: PropTypes.node,
};

WizardHeading.defaultProps = {
  children: null,
};

export default WizardHeading;

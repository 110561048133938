// Static Steps / Pages / Views the FW can show
export const STEP_START = 'STEP_START';
export const STEP_CALCULATION = 'STEP_CALCULATION';
export const STEP_USER_DATA = 'STEP_USER_DATA';

// Possible field types
export const FIELD_TYPE_CHOICE = 'choice';
export const FIELD_TYPE_ADDRESS = 'address';
export const FIELD_TYPE_POSTAL = 'postal_code';
export const FIELD_TYPE_NUMERICAL = 'numerical';
export const FIELD_TYPE_TEXT = 'text';

// Possible variants
export const VARIANTS = {
  DEFAULT: 'default',
  HIGHLIGHTED: 'highlighted',
  CLEAN: 'clean',
  CTA: 'cta',
  CTA2: 'cta2',
  EXTENDED: 'extended',
  RAFFLE: 'raffle',
};

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@hausgold/designsystem';

const UserDataStepLayoutContainer = ({ children, ...props }) => (
  <Box className="user-data" mt={8} {...props}>
    {children}
  </Box>
);

UserDataStepLayoutContainer.propTypes = {
  children: PropTypes.node,
};

UserDataStepLayoutContainer.defaultProps = {
  children: null,
};

export default UserDataStepLayoutContainer;

/* eslint-disable max-len */
import qs from 'qs';

/**
 * Build URL for the registration iframe
 * @param email
 * @param firstName
 * @param lastName
 * @param leadId
 * @param type
 * @param gender
 * @param config the application config
 * @returns {string}
 */
const buildRedirectUrl = ({
  email = '',
  firstName = '',
  lastName = '',
  leadId = '',
  type = '',
  gender = '',
  config = {},
}) => {
  // Construct an absolute URL here, as this is also used from within the
  // package which is embedded in hausgold.de, therefore relative URL does
  // not work over there
  const baseUrl = `${config.url}/registration/formwizard`;

  const parameters = {
    'user[email]': email,
    'user[first_name]': firstName,
    'user[last_name]': lastName,
    'user[gender]': gender,
    'lead[id]': leadId,
    'lead[type]': type,
  };

  if (window?.ga?.loaded) {
    window.ga((tracker) => {
      parameters.clientId = tracker.get('clientId');
    });
  }

  if (!firstName) delete parameters['user[first_name]'];
  if (!lastName) delete parameters['user[last_name]'];
  if (!gender) delete parameters['user[gender]'];

  const urlEncodedParameters = qs.stringify(parameters);

  return `${baseUrl}?${urlEncodedParameters}`;
};

export default buildRedirectUrl;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@hausgold/designsystem';

const StartLayoutContainer = ({ children, ...props }) => (
  <Box className="start-container" {...props}>
    {children}
  </Box>
);

StartLayoutContainer.propTypes = {
  children: PropTypes.node,
};

StartLayoutContainer.defaultProps = {
  children: null,
};

export default StartLayoutContainer;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as snippet from '@segment/snippet';
import { ApiContext, WizardContext } from 'fw-utils/contexts';
import { STEP_START } from 'fw-utils/CONSTANTS';
import WizardNavigation from './WizardNavigation';
import formwizardApi, { setInstance } from '../services/api/formwizardApi';
import { getComponents } from '../components';

/* Fix potential security issue running eval in local scope by making it global.
 * See https://rollupjs.org/guide/en/#avoiding-eval
 */
// eslint-disable-next-line no-eval, no-var
var evalGlobal = eval;

/**
 * Define how often the data request will retry if something went wrong.
 * Value of '2' mean 3 requests in total.
 * @type {number}
 */
const MAX_RETRIES = 2;

/**
 * Define the time (in ms) to wait before request again in case of an error.
 * @type {number}
 */
const REQUEST_RETRY_WAITING_TIME = 3000;

/**
 * The Formwizard.
 *
 * This is the entry point of the library where everything global is set up.
 * At the same time, this is the point that is exposed to consumers.
 *
 * What it does:
 *   - Sets up the contexts
 *   - Calls the API
 *   - Sets up tracking
 *   - Initiates everything / rendering
 *
 * @param dialogId
 * @param leadId
 * @param recruiterId
 * @param config
 * @param isLocal
 */
const Formwizard = ({
  dialogId,
  dialogConfig,
  leadId,
  recruiterId,
  components: customComponents,
  config,
  isLocal,
  position,
  variant,
  doTrack,
  // This is for testing purpose only
  extendedContent,
  appsignal,
}) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestRetries, setRequestRetries] = useState(0);

  const [components] = useState(getComponents(customComponents));

  const [currentLine, setCurrentLine] = useState(null);
  const [currentStep, setCurrentStep] = useState(STEP_START);
  const [userData, setUserData] = useState({
    gender: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  const [stepData, setStepData] = useState({});
  const [redirectUrl, setRedirectUrl] = useState(null);

  /**
   * Merge normal wizard data with extended hard-coded wizard data if available
   * @param {object} newDialogConfig
   */
  const setDialogData = (newDialogConfig) => {
    setData({ ...newDialogConfig, ...extendedContent });
  };

  // Setup segment / analytics if not already initialized from app and key exists.
  useEffect(() => {
    if (doTrack && !window?.analytics && config?.analytics?.segmentKey) {
      const opts = {
        apiKey: config.analytics.segmentKey,
        // We do NOT want to track pages because it causes a segment call overload because the Wizard is built into
        // every page of hausgold.de
        //
        // We track the steps via segment events inside the formwizard itself.
        page: false,
      };

      let segmentScript;

      if (isLocal) {
        segmentScript = snippet.max(opts);
      } else {
        segmentScript = snippet.min(opts);
      }

      // Needed to run segment script (delivered as string) which enables window.analytics
      evalGlobal(segmentScript);
    }
  }, []);

  // Create instance of formwizard API with given config
  useEffect(() => {
    setInstance(config, { variant }, appsignal, isLocal);
  }, []);

  // Load wizard data
  useEffect(() => {
    // Ensure API is initialized and dialog id is defined and dialog itself is not given.
    if (formwizardApi && dialogId && !dialogConfig) {
      setLoading(true);

      formwizardApi()
        .getDialog({ id: dialogId })
        .then((response) => response?.data)
        .then((dialogData) => {
          setDialogData(dialogData);
          setLoading(false);
        })
        .catch((_error) => {
          // Count up the amount of failed requests.
          if (requestRetries < MAX_RETRIES) {
            setTimeout(() => {
              setRequestRetries(requestRetries + 1);
            }, REQUEST_RETRY_WAITING_TIME);

            return;
          }

          if (appsignal) {
            appsignal.send(
              appsignal.createSpan((span) => {
                span.setAction('User was redirected to error page');
                span.setError(_error);
                span.setTags({
                  retry: requestRetries,
                });
              })
            );
          }

          // If retry did not help stop and tell it to the user...
          setError(_error);
          setLoading(false);
        });

      // If dialog itself is given use it directly
    } else if (dialogConfig) {
      setDialogData(dialogConfig);
    }
  }, [formwizardApi, dialogId, dialogConfig, requestRetries]);

  // Trigger when step changes
  useEffect(() => {
    // Track to segment, except if the step is the start page.
    if (currentStep !== STEP_START) {
      window?.analytics?.track('step_changed', {
        step: currentStep,
        line: currentLine,
      });
    }
  }, [currentStep, currentLine]);

  const bgColor = data?.options?.background_color
    ? data.options.background_color
    : 'inherit';

  // Produce a custom window event with the formwizard dialog configured
  // background color, in order to be able to set it on the Next.js surrounding
  // page
  useEffect(() => {
    const event = new CustomEvent('hg-fw-background-color', {
      detail: bgColor,
    });
    window?.dispatchEvent(event);
  }, [bgColor]);

  return (
    <ApiContext.Provider
      value={{
        error,
        isLoading: loading,
        data,
        dialogId,
        config,
        // Used for extended wizard
        redirectUrl,
        setRedirectUrl,
      }}
    >
      <WizardContext.Provider
        value={{
          components,
          leadId,
          recruiterId,
          dialogId,
          position,
          currentLine,
          currentStep,
          userData,
          stepData,
          setStepData,
          setUserData: (newUserData) => {
            // Track user data to segment
            window?.analytics?.identify(newUserData?.email, newUserData);

            // Set user data in state
            setUserData(newUserData);
          },
          setCurrentLine,
          setCurrentStep,
        }}
      >
        <div id={`variant_${variant}_dialog_${dialogId}`}>
          <WizardNavigation bgColor={bgColor} />
        </div>
      </WizardContext.Provider>
    </ApiContext.Provider>
  );
};

Formwizard.propTypes = {
  dialogId: PropTypes.string,
  dialogConfig: PropTypes.object,
  leadId: PropTypes.string,
  recruiterId: PropTypes.string,
  variant: PropTypes.string,
  components: PropTypes.shape({
    WizardHeading: PropTypes.func,
  }),
  config: PropTypes.shape({
    api: PropTypes.shape({
      urls: PropTypes.shape({
        wizard: PropTypes.shape({
          http: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    analytics: PropTypes.shape({
      segmentKey: PropTypes.string,
    }),
  }).isRequired,
  isLocal: PropTypes.bool,
  doTrack: PropTypes.bool,
  position: PropTypes.oneOf(['right', 'top', 'header']),
  extendedContent: PropTypes.object,
  appsignal: PropTypes.object,
};

Formwizard.defaultProps = {
  dialogId: null,
  dialogConfig: null,
  leadId: null,
  recruiterId: null,
  isLocal: false,
  components: {},
  position: null,
  variant: 'default',
  extendedContent: {},
  doTrack: true,
  appsignal: null,
};

Formwizard.displayName = 'Formwizard';

export default Formwizard;

import { useRouter } from 'next/router';
import { useMemo } from 'react';
import Cookies from 'js-cookie';

/**
 * `leadId` retrieval logic
 *
 * Priority:
 *   1. Parameter `lkey`
 *   2. Parameter `leadid`
 *   3. Cookie `lkey`
 * parameter `lkey`, `leadid` or the cookie `lkey`.
 * @return {unknown}
 */
const useLeadId = () => {
  const { query } = useRouter();

  return useMemo(() => {
    // Cookies & parameter
    const keyCookie = Cookies.get('lkey');
    const caseInsensitiveParameters = {};

    // This iterates over every query parameter and lowercases the keys.
    // The reason for this is to make the `leadid` parameter case-insensitive.
    // By default, query params are case-sensitive.

    Array.from(Object.keys(query)).forEach((key) => {
      caseInsensitiveParameters[key.toLowerCase()] = query[key];
    });

    // Get's the leadId parameter, either as `lkey` or `leadid`.
    const { lkey, leadid } = caseInsensitiveParameters;

    return lkey || leadid || keyCookie;
  }, [query]);
};

export default useLeadId;

/* eslint-disable react/no-array-index-key */

import {
  Box,
  Button,
  Checkbox,
  Col,
  Feedback,
  Flex,
  HeadingH3,
  HStack,
  Label,
  Link,
  Radio,
  RadioGroup,
  Row,
  Spinner,
  Text,
} from '@hausgold/designsystem';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { ApiContext, WizardContext } from 'fw-utils/contexts';
import sendTracking from 'fw-utils/tracking';
import 'fw-utils/yupPhone';
import { Parser } from 'html-to-react';
import { map } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { Fragment, useContext, useState } from 'react';
import * as Yup from 'yup';
import formwizardApi from '../../../services/api/formwizardApi';
import {
  getAdditionalContentText,
  getAuthenticityToken,
  getContactDescriptionText,
  getMandatoryFieldOptions,
  getNextButtonText,
  getOptinContentText,
  getOptinType,
  getVersion,
} from '../../../services/models/dialog';
import FluidMaxContainer from '../../FluidMaxContainer';
import Input from '../../Input';

const htmlToReactParser = new Parser();

/**
 * Get `html-to-react` processing instructions
 * @param type {'default'|'additionalContent'}
 * @return {[{shouldProcessNode: (function(*): boolean), processNode: (function(*): *)}]}
 */
const getHtmlToReactParserProcessingInstructions = (type = 'default') => [
  {
    // This processing instruction correctly processes various tags, but removes the `<p />` tag
    // outside.
    //
    // We have to render links as `<Link />`, for example.
    shouldProcessNode: (node) => node.name === 'p',
    processNode: (node) =>
      map(node.children, (child, index) => {
        if (child.type === 'comment') {
          return null;
        }

        if (child.name === 'a') {
          return (
            <Link
              {...child.attribs}
              fontSize="inherit"
              key={index}
              colorScheme={type !== 'additionalContent' ? 'gray' : 'blue'}
              textDecoration={type !== 'additionalContent' && 'underline'}
            >
              {child.children.map((subChild) => subChild.data)}
            </Link>
          );
        }

        if (child.name === 'br') {
          return <br key={index} />;
        }

        if (child.name === 'strong') {
          return (
            <strong key={index}>
              {child.children.map((subChild) => subChild.data)}
            </strong>
          );
        }

        return child.data;
      }),
  },
];

const SubmitButton = ({ isLoading, children }) => (
  <Button
    type="submit"
    color="white"
    variant="blue"
    border="2px"
    boxShadow="xl"
    width="100%"
    borderColor="blue.400"
    backgroundColor="blue.400"
    mt={3}
    mb={3}
    _hover={{
      borderColor: 'blue.400',
      backgroundColor: 'blue.400',
    }}
    _focus={{
      borderColor: 'blue.500',
      backgroundColor: 'blue.500',
    }}
  >
    {isLoading ? <Spinner color="white" size="md" /> : children}
  </Button>
);

SubmitButton.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
};

SubmitButton.defaultProps = {
  isLoading: false,
  children: {},
};
/**
 * Build validation schema
 * @param mandatoryFields
 */
const buildValidationSchema = (mandatoryFields) =>
  Yup.object().shape({
    gender: mandatoryFields.gender
      ? Yup.string().required('Bitte wählen Sie eine Anrede aus.')
      : Yup.string(),
    firstName: mandatoryFields.firstName
      ? Yup.string().trim().required('Bitte geben Sie Ihren Vornamen ein.')
      : Yup.string(),
    lastName: mandatoryFields.lastName
      ? Yup.string().trim().required('Bitte geben Sie Ihren Nachnamen ein.')
      : Yup.string(),
    email: Yup.string()
      .email(
        'Geben Sie eine gültige E-Mail-Adresse für die kostenlose Immobilienbewertung an.'
      )
      .required(
        'Geben Sie eine gültige E-Mail-Adresse für die kostenlose Immobilienbewertung an.'
      ),
    phone: Yup.string()
      .phone(
        'DE',
        false,
        'Geben Sie eine gültige Telefonnummer für die kostenlose Immobilienbewertung an.'
      )
      .trim()
      .required(
        'Geben Sie eine gültige Telefonnummer für die kostenlose Immobilienbewertung an.'
      ),
    optIn: mandatoryFields.optIn
      ? Yup.bool().oneOf(
          [true],
          'Bitte akzeptieren Sie die Datenschutzbestimmungen.'
        )
      : Yup.mixed(),
  });

/**
 * Adds the contact form at the end of the wizard
 */
const UserDataStep = () => {
  const { data, config } = useContext(ApiContext);

  const {
    setUserData,
    userData,
    dialogId,
    currentLine,
    leadId,
    recruiterId,
    stepData,
    components: { UserDataStepLayoutContainer },
  } = useContext(WizardContext);

  const [isLoading, setIsLoading] = useState(false);
  const optinType = getOptinType(data) || 'hint';

  const mandatoryFields = getMandatoryFieldOptions(data);

  return (
    <FluidMaxContainer>
      <UserDataStepLayoutContainer>
        <Formik
          initialValues={userData}
          validationSchema={buildValidationSchema({
            gender: mandatoryFields?.gender ?? true,
            firstName: mandatoryFields?.first_name ?? true,
            lastName: mandatoryFields?.last_name ?? true,
            optIn: optinType === 'checkbox' && getOptinContentText(data),
          })}
          onSubmit={(values) => {
            formwizardApi()
              .processLead({
                config,
                currentLine,
                data,
                dialogId,
                leadId,
                recruiterId,
                stepData,
                values,
                getAuthenticityToken,
                getVersion,
                setIsLoading,
                setUserData,
              })
              .then(({ response }) => {
                const { leadid: apiLeadId } = response?.data;

                // Track last user interaction
                sendTracking('click_formwizard', {
                  label: 'last_click',
                  category: 'formwizard_interaction',
                });

                // Redirect to the Pricehubble-Online-Bewertung-LP
                // add parameter - lead-id, appraised value
                const redirectToPricehubbleLp = `https://www.hausgold.de/lp/gewinnspiel-immobilienwert-2/?leadid=${apiLeadId}&appraisedvalue=${stepData.Immobilienwert}`;

                // NOTE: Use +window.top.location+ to break out of the
                //       iframe (redirect the whole page/tab).
                window.location.href = redirectToPricehubbleLp;
              });
          }}
        >
          {/* FIXME: No error message for firstName & gender?! */}
          <Form>
            <Row mt={4}>
              <Col
                width={{
                  base: 1 / 1,
                  md: 7 / 12,
                }}
                fontSize="lg"
              >
                <HeadingH3 mb={6}>{getContactDescriptionText(data)}</HeadingH3>

                <Text
                  textAlign="left"
                  mb={2}
                  color="gray.400"
                  display={{ base: 'none', md: 'block' }}
                >
                  * Pflichtfelder
                </Text>

                <Box mb={3}>
                  <Flex alignItems="center">
                    <Text
                      as="span"
                      mr={6}
                      mb={0}
                      fontSize="lg"
                      color="gray.400"
                    >
                      Anrede
                      {mandatoryFields.gender ? '*' : ''}
                    </Text>

                    <RadioGroup name="gender">
                      <HStack>
                        <Field name="gender" value="male">
                          {({ field, meta }) => (
                            <Fragment>
                              <Radio
                                isInvalid={Boolean(meta.touched && meta.error)}
                                id={`${field.name}-male`}
                                {...field}
                                value="male"
                              >
                                <Text as="span" fontSize="lg" color="gray.400">
                                  Herr
                                </Text>
                              </Radio>
                            </Fragment>
                          )}
                        </Field>

                        <Field name="gender" value="female">
                          {({ field, meta }) => (
                            <Fragment>
                              <Radio
                                isInvalid={Boolean(meta.touched && meta.error)}
                                id={`${field.name}-female`}
                                {...field}
                                value="female"
                              >
                                <Text as="span" fontSize="lg" color="gray.400">
                                  Frau
                                </Text>
                              </Radio>
                            </Fragment>
                          )}
                        </Field>
                      </HStack>
                    </RadioGroup>
                  </Flex>
                  <ErrorMessage name="gender" component={Feedback} />
                </Box>
                <Row>
                  <Col
                    mb={3}
                    width={{
                      base: 1,
                      md: 1 / 2,
                    }}
                  >
                    <Field name="firstName">
                      {({ field, meta }) => (
                        <Fragment>
                          <Label
                            color="gray.400"
                            htmlFor={field.name}
                            isRequired={mandatoryFields?.first_name}
                          >
                            Vorname
                          </Label>
                          <Input
                            isInvalid={Boolean(meta.touched && meta.error)}
                            id={field.name}
                            width="100%"
                            bg="white"
                            {...field}
                          />
                          <ErrorMessage
                            name={field.name}
                            component={Feedback}
                          />
                        </Fragment>
                      )}
                    </Field>
                  </Col>
                  <Col
                    mb={3}
                    width={{
                      base: 1,
                      md: 1 / 2,
                    }}
                  >
                    <Field name="lastName">
                      {({ field, meta }) => (
                        <Fragment>
                          <Label
                            color="gray.400"
                            htmlFor={field.name}
                            isRequired={mandatoryFields?.last_name}
                          >
                            Nachname
                          </Label>
                          <Input
                            isInvalid={Boolean(meta.touched && meta.error)}
                            id={field.name}
                            width="100%"
                            bg="white"
                            {...field}
                          />
                          <ErrorMessage
                            name={field.name}
                            component={Feedback}
                          />
                        </Fragment>
                      )}
                    </Field>
                  </Col>
                </Row>
                <Box mb={3}>
                  <Field name="phone">
                    {({ field, meta }) => (
                      <Fragment>
                        <Label color="gray.400" htmlFor={field.name} isRequired>
                          Telefon
                        </Label>
                        <Input
                          isInvalid={Boolean(meta.touched && meta.error)}
                          type="tel"
                          id={field.name}
                          width="100%"
                          bg="white"
                          {...field}
                        />
                        <ErrorMessage name={field.name} component={Feedback} />
                      </Fragment>
                    )}
                  </Field>
                </Box>
                <Box mb={3}>
                  <Field name="email">
                    {({ field, meta }) => (
                      <Fragment>
                        <Label color="gray.400" htmlFor={field.name} isRequired>
                          E-Mail
                        </Label>
                        <Input
                          isInvalid={Boolean(meta.touched && meta.error)}
                          type="email"
                          id={field.name}
                          width="100%"
                          bg="white"
                          {...field}
                        />
                        <ErrorMessage name={field.name} component={Feedback} />
                      </Fragment>
                    )}
                  </Field>
                </Box>

                {optinType === 'checkbox' && getOptinContentText(data) && (
                  <Field name="optIn">
                    {({ field, meta }) => (
                      <Box mb={7}>
                        <Flex>
                          <Checkbox
                            isInvalid={Boolean(meta.touched && meta.error)}
                            id={field.name}
                            {...field}
                          />
                          <Label
                            isRequired
                            color="gray.400"
                            htmlFor={field.name}
                          >
                            {htmlToReactParser.parseWithInstructions(
                              getOptinContentText(data),
                              () => true,
                              getHtmlToReactParserProcessingInstructions()
                            )}
                          </Label>
                        </Flex>
                        <ErrorMessage name={field.name} component={Feedback} />
                      </Box>
                    )}
                  </Field>
                )}
              </Col>
              <Col
                width={{
                  base: 1 / 1,
                  md: 5 / 12,
                }}
              >
                <Flex height="100%" alignItems="flex-end">
                  <Box>
                    {getAdditionalContentText(data) && (
                      <Text as="span" mb={0} color="gray.400" display="block">
                        {htmlToReactParser.parseWithInstructions(
                          getAdditionalContentText(data),
                          () => true,
                          getHtmlToReactParserProcessingInstructions(
                            'additionalContent'
                          )
                        )}
                      </Text>
                    )}
                    {optinType === 'hint' && getOptinContentText(data) && (
                      <Text
                        as="span"
                        mt={2}
                        mb={2}
                        color="gray.400"
                        display="block"
                      >
                        {htmlToReactParser.parseWithInstructions(
                          getOptinContentText(data),
                          () => true,
                          getHtmlToReactParserProcessingInstructions()
                        )}
                      </Text>
                    )}
                    <Box display={{ base: 'block', md: 'none', margin: '5px' }}>
                      <SubmitButton isLoading={isLoading}>
                        {getNextButtonText(data)}
                      </SubmitButton>
                    </Box>
                  </Box>
                </Flex>
              </Col>
            </Row>
          </Form>
        </Formik>
      </UserDataStepLayoutContainer>
    </FluidMaxContainer>
  );
};

export default UserDataStep;

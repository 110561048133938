import React from 'react';
import Layout from './Layout';
import Formwizard from '../../../wizard';

import WizardHeading from './WizardHeading';
import Footer from './Footer';
import StartLayoutContainer from './StartLayoutContainer';
import UserDataStepLayoutContainer from './UserDataStepLayoutContainer';
import RendererLayoutContainer from './RendererLayoutContainer';
import NavigationButtonsLayoutContainer from './NavigationButtonsLayoutContainer';
import CalculationLayoutContainer from './CalculationLayoutContainer';

const Highlighted = React.forwardRef(({ ...props }, ref) => (
  <Layout ref={ref}>
    <Formwizard
      components={{
        WizardHeading,
        StartLayoutContainer,
        UserDataStepLayoutContainer,
        RendererLayoutContainer,
        NavigationButtonsLayoutContainer,
        CalculationLayoutContainer,
      }}
      {...props}
    />
    <Footer />
  </Layout>
));

export default Highlighted;

import React from 'react';
import PropTypes from 'prop-types';
import { Row } from '@hausgold/designsystem';

const ChoiceOptionRowContainer = ({ children, ...props }) => (
  <Row justifyContent="center" ml={-4} {...props}>
    {children}
  </Row>
);

ChoiceOptionRowContainer.propTypes = {
  children: PropTypes.node,
};

ChoiceOptionRowContainer.defaultProps = {
  children: null,
};

export default ChoiceOptionRowContainer;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from '@hausgold/designsystem';

const Layout = React.forwardRef(({ children, ...props }, ref) => (
  <Fragment>
    <Flex
      // Note: Height chances on this place will break iframes as the ref is linking to the inner element,
      //   so extra height space will result in cutting of content.
      //  The `ref` itself can't be attached here because flex as not an absolute height value
      //  which can be taken - so it is nested.
      className="layout"
      height="100%"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Box ref={ref} padding={8}>
        <Box width="100%" mt="auto" mb="auto" className="main">
          <div>{children}</div>
        </Box>
      </Box>
    </Flex>
  </Fragment>
));

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;

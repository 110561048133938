import React from 'react';
import { Box } from '@hausgold/designsystem';

/**
 * Renders Trustbox widget.
 * For more information see
 * https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-to-a-single-page-application
 * @returns {JSX.Element}
 * @constructor
 */
const TrustBox = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <Box>
      <div
        ref={ref} // We need a reference to this element to load the TrustBox in the effect.
        className="trustpilot-widget"
        data-template-id="53aa8807dec7e10d38f59f32"
        data-businessunit-id="5d08a845e18cd100018a0b17"
        data-locale="de-DE"
        data-style-height="83px"
        data-style-width="135px"
        data-theme="null"
      >
        <a
          href="https://www.trustpilot.com/review/hausgold.de"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </div>
      <Box textAlign="left" fontSize="11px" lineHeight="13px" color="#191919">
        Seit Juni 2019
      </Box>
    </Box>
  );
};

export default TrustBox;

import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner, Flex, HeadingH1 } from '@hausgold/designsystem';
import { ApiContext, WizardContext } from 'fw-utils/contexts';
import { STEP_USER_DATA } from 'fw-utils/CONSTANTS';
import { getSpinnerDuration, getSpinnerText } from '../services/models/dialog';
import FluidMaxContainer from '../components/FluidMaxContainer';

const CalculationStep = ({ shouldRedirectAfterTimeout }) => {
  const { data } = useContext(ApiContext);
  const {
    setCurrentStep,
    components: { CalculationLayoutContainer },
  } = useContext(WizardContext);

  useEffect(() => {
    // Do not redirect when `shouldRedirectAfterTimeout` is false.
    if (!shouldRedirectAfterTimeout) {
      return () => {};
    }

    const timeout = setTimeout(() => {
      setCurrentStep(STEP_USER_DATA);
    }, getSpinnerDuration(data) || 5000);

    // Clear timeout when unmounting
    return function cleanup() {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <FluidMaxContainer>
      <CalculationLayoutContainer>
        <HeadingH1 as="div" mb={12}>
          {getSpinnerText(data)}
        </HeadingH1>
        <Flex>
          <Spinner className="spinner" size="xl" mx="auto" />
        </Flex>
      </CalculationLayoutContainer>
    </FluidMaxContainer>
  );
};

CalculationStep.propTypes = {
  shouldRedirectAfterTimeout: PropTypes.bool,
};

CalculationStep.defaultProps = {
  shouldRedirectAfterTimeout: true,
};

export default CalculationStep;

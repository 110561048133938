import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@hausgold/designsystem';
import DynamicIcon from 'fw-components/Functional/DynamicIcon';
import { FIELD_TYPE_CHOICE } from 'fw-utils/CONSTANTS';
import { ApiContext, WizardContext } from 'fw-utils/contexts';
import Renderer from './Fields/Renderer';
import NavigationButtons from './NavigationButtons';
import {
  getStep,
  getStepKeys,
  shouldShowNavigationButtons,
} from '../../services/models/dialog';
import FluidMaxContainer from '../../components/FluidMaxContainer';

const Step = ({ lineKey, stepKey }) => {
  const { data } = useContext(ApiContext);
  const {
    components: { WizardHeading, RendererLayoutContainer },
  } = useContext(WizardContext);

  const step = getStep(data, lineKey, stepKey);
  const steps = getStepKeys(data, lineKey);

  const shouldAddMandatoryMarker =
    step?.type === FIELD_TYPE_CHOICE && step?.options?.mandatory;
  const stepTitle = step?.title + (shouldAddMandatoryMarker ? ' *' : '');

  const icon = step?.icon;
  const iconSvg = step?.icon_svg;

  return (
    <Fragment>
      <div className="wizard-question" data-line={lineKey} data-step={stepKey}>
        <WizardHeading>{stepTitle}</WizardHeading>
        {icon !== null && (
          <Box
            textAlign="center"
            display={{ base: 'none', sm: 'inherit' }}
            mb={12}
          >
            <DynamicIcon iconId={icon} iconSvg={iconSvg} h="56px" w="auto" />
          </Box>
        )}

        <FluidMaxContainer>
          <RendererLayoutContainer>
            <Renderer key={stepKey} stepKey={stepKey} lineKey={lineKey} />
          </RendererLayoutContainer>
        </FluidMaxContainer>
      </div>

      {shouldShowNavigationButtons(data) && (
        <FluidMaxContainer>
          <NavigationButtons
            totalSteps={steps?.length}
            currentStepIndex={step?.position - 1}
            stepKey={stepKey}
            stepType={step?.type}
          />
        </FluidMaxContainer>
      )}
    </Fragment>
  );
};

Step.propTypes = {
  lineKey: PropTypes.string.isRequired,
  stepKey: PropTypes.string.isRequired,
};

export default Step;

import { Box, Button, Flex } from '@hausgold/designsystem';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { ApiContext, WizardContext } from 'fw-utils/contexts';
import {
  getBackButtonText,
  getNextButtonText,
} from '../../../services/models/dialog';
import ProgressBarCTA from './ProgressBarCTA';

const CustomNavigationButtons = ({
  totalSteps,
  currentStepIndex,
  nextStepKey,
  handlePreviousButtonClick,
  handleNextButtonClick,
}) => {
  const { data } = useContext(ApiContext);
  const {
    components: { NavigationButtonsLayoutContainer },
  } = useContext(WizardContext);

  return (
    <NavigationButtonsLayoutContainer flexDirection="column">
      <Box textAlign="center" />
      <Box width="100%">
        <Flex
          width="100%"
          justifyContent={currentStepIndex >= 0 ? 'space-between' : 'flex-end'}
          mb={4}
        >
          {currentStepIndex >= 0 && (
            <Button
              colorScheme="darkRed"
              as="a"
              className="back"
              onClick={handlePreviousButtonClick}
            >
              {getBackButtonText(data)}
            </Button>
          )}
          <Button
            colorScheme="red"
            as={nextStepKey ? 'a' : 'button'}
            type={nextStepKey ? null : 'submit'}
            className="next"
            onClick={handleNextButtonClick}
          >
            {getNextButtonText(data)}
          </Button>
        </Flex>

        <ProgressBarCTA
          currentStepIndex={currentStepIndex}
          totalSteps={totalSteps}
        />
      </Box>
    </NavigationButtonsLayoutContainer>
  );
};

CustomNavigationButtons.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  currentStepIndex: PropTypes.number.isRequired,
  nextStepKey: PropTypes.string.isRequired,
  handlePreviousButtonClick: PropTypes.func.isRequired,
  handleNextButtonClick: PropTypes.func.isRequired,
};

export default CustomNavigationButtons;

import { createContext } from 'react';
import { getComponents } from '../components';

export const ApiContext = createContext({
  dialogId: null,
  leadId: null,
  data: null,
  isLoading: false,
  error: false,
  config: {},
  // Used for extended wizard only
  redirectUrl: null,
  setRedirectUrl: () => {},
});

export const WizardContext = createContext({
  components: getComponents({}),
  dialogId: null,
  leadId: null,
  recruiterId: null,
  position: 'default',
  currentLine: null,
  currentStep: null,
  userData: {},
  stepData: {},
  setCurrentLine: () => {},
  setCurrentStep: () => {},
  setUserData: () => {},
  setStepData: () => {},
});

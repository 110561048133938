import axios from 'axios';
import { omit } from 'lodash-es';
import ArgumentPath from './argumentPath';

/**
 * API Client
 *
 * API base class for generating individual API clients for each API with their
 * own methods.
 *
 * It has support for replacing parameters in paths automatically, interceptors,
 * headers and timeouts.
 */
export default class ApiClient {
  isLocal = null;

  baseUrl = null;

  headers = null;

  interceptors = null;

  instance = null;

  // Time in ms the request waits for a server response
  timeout = 3000;

  /**
   * @param {boolean} isLocal
   * @param {string} baseUrl
   * @param {object} headers
   * @param {object} interceptors
   * @param {object} interceptors.request
   * @param {func} interceptors.request.onFulfilled
   * @param {func} interceptors.request.onRejected
   * @param {object} interceptors.response
   * @param {func} interceptors.response.onFulfilled
   * @param {func} interceptors.response.onRejected
   * @param {number} timeout
   */
  constructor(isLocal, { baseUrl, headers, interceptors, timeout }) {
    if (!baseUrl || typeof baseUrl !== 'string') {
      throw new Error('ApiClient must be given a valid `baseUrl` string.');
    }

    this.isLocal = isLocal;
    this.baseUrl = baseUrl;
    this.headers = headers || this.headers;
    this.interceptors = interceptors || this.interceptors;
    this.timeout = timeout || this.timeout;

    // Create & setup axios instance
    this.instance = axios.create({
      baseURL: this.baseUrl,
      headers: this.headers,
      timeout: this.timeout,
    });

    // Apply interceptors
    this.instance.interceptors.request.use(
      this.interceptors?.request?.onFulfilled,
      this.interceptors?.request?.onRejected
    );

    this.instance.interceptors.response.use(
      this.interceptors?.response?.onFulfilled,
      this.interceptors?.response?.onRejected
    );
  }

  /**
   * Default request function
   *
   * Auto-replaces parameters in `url` (e.g. `{id}`) with the given properties
   * in `data` via the `ArgumentPath` class.
   *
   * @param {object} headers
   * @param {string} method
   * @param {string} url
   * @param {object} data
   * @param {object} params
   * @param rest
   * @returns {Promise<void>}
   */
  request = async ({ headers, method = 'get', url, data, params, ...rest }) => {
    const argumentPath = new ArgumentPath({
      path: url,
      args: data,
      isLocal: this.isLocal,
    });

    return this.instance.request({
      method,
      headers,
      // Use the processed url and data object from `argumentPath` if there are
      // parameters in the URL
      url: argumentPath.hasParameters ? argumentPath.processed : url,
      data: argumentPath.hasParameters
        ? argumentPath.argsWithoutParameters
        : data,
      params,
      ...rest,
    });
  };

  /**
   * Request function with an `accessToken` requirement.
   *
   * @param {object} headers
   * @param {string} method
   * @param {string} url
   * @param {object} data
   * @param {object} params
   * @param {string} accessToken
   * @param rest
   * @returns {Promise<void>}
   */
  requestWithAccessToken = async ({
    headers,
    method,
    url,
    data,
    params,
    accessToken,
    ...rest
  }) => {
    if (!accessToken) {
      throw new Error('`accessToken` is required.');
    }

    return this.request({
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...headers,
      },
      method,
      url,
      data,
      params,
      ...rest,
    });
  };

  /**
   * Request function with a `refreshToken` requirement.
   *
   * @param {object} headers
   * @param {string} method
   * @param {string} url
   * @param {object} data
   * @param {string} data.refreshToken
   * @param {object} params
   * @param {string} params.refreshToken
   * @param rest
   * @returns {Promise<void>}
   */
  requestWithRefreshToken = async ({
    headers,
    method,
    url,
    data,
    params,
    ...rest
  }) => {
    const refreshTokenAsParam = params?.refreshToken;
    const refreshTokenAsData = data?.refreshToken;

    if (!refreshTokenAsParam && !refreshTokenAsData) {
      throw new Error('`refreshToken` is required as param or data.');
    }

    return this.request({
      method,
      url,
      data: {
        refresh_token: refreshTokenAsData,
        ...omit(data, 'refreshToken'),
      },
      params: {
        refresh_token: refreshTokenAsParam,
        ...omit(params, 'refreshToken'),
      },
      ...rest,
    });
  };
}

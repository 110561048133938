import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import { Feedback, Text, Flex } from '@hausgold/designsystem';
import { ApiContext, WizardContext } from 'fw-utils/contexts';
import WizardCard from '../../../components/WizardCard';
import {
  getNextStepKey,
  getNextStepPosition,
} from '../../../services/models/dialog';

const Choice = ({ options, stepKey, lineKey, mandatory }) => {
  const [field, meta, helpers] = useField(stepKey);
  const { data } = useContext(ApiContext);
  const {
    setCurrentStep,
    position,
    components: {
      CustomWizardCard,
      ChoiceOptionColContainer,
      ChoiceOptionRowContainer,
    },
  } = useContext(WizardContext);
  const formikContext = useFormikContext();

  const handleOnClick = ({ event, value }) => {
    event.preventDefault();
    helpers.setValue(value);

    const nextStepKey = getNextStepKey(data, lineKey, stepKey);
    const nextStepPosition = getNextStepPosition(data, lineKey, stepKey);

    // If there is no next step key, submit the form.
    if (!nextStepKey) {
      // Track user progress (last Step; Next is "Kontaktformular").
      window?.analytics?.track(`${lineKey}-clickto_step_Kontaktformular`, {
        label: value,
        category: 'formwizard',
      });
      /*
       * Note: `As helpers.setValue(value)` is async the `submitForm()` will stop
       * because the value is not set at the moment the field is required but right after.
       * However, a second click on the field will do the trick, and I assume most of
       * the user will simply click again if first click has not the expected impact.
       * Additionally to that, before the change the user had no chance to come to
       * the contact form if `Choice` was the last step at all, as she was redirected
       * to the very beginning instead. So at least it is some improvement.
       * More effort could be done to fix this issue. But this will not be a problem
       * in real world as `Choice` never is the last step and the overall refactoring
       * will fix this as well.
       */
      formikContext.submitForm();
    } else {
      // Track user progress
      window?.analytics?.track(
        `${lineKey}-clickto_step${nextStepPosition}_${nextStepKey}`,
        {
          label: value,
          category: 'formwizard',
        }
      );

      setCurrentStep(nextStepKey);
    }
  };

  return (
    <Fragment>
      <ChoiceOptionRowContainer>
        {options.choices.map((choice) => (
          <ChoiceOptionColContainer
            key={choice.title}
            position={position}
            context="choice"
          >
            {CustomWizardCard ? (
              <CustomWizardCard
                isActive={field.value === choice.value}
                iconId={choice?.icon}
                iconSvg={choice?.icon_svg}
                title={choice.title}
                onClick={(event) =>
                  handleOnClick({ event, value: choice.value })
                }
              />
            ) : (
              <WizardCard
                isActive={field.value === choice.value}
                iconId={choice?.icon}
                iconSvg={choice?.icon_svg}
                title={choice.title}
                onClick={(event) =>
                  handleOnClick({ event, value: choice.value })
                }
              />
            )}
          </ChoiceOptionColContainer>
        ))}
      </ChoiceOptionRowContainer>
      <Flex mt={2} justifyContent="space-between">
        <ErrorMessage name={stepKey}>
          {(message) => (
            <Feedback mb={0} mx="auto" display="block">
              {message}
            </Feedback>
          )}
        </ErrorMessage>

        {mandatory && !(meta.touched && meta.error) && (
          <Text as="div" mx="auto" color="gray.400" mb={0}>
            * Pflichtfeld
          </Text>
        )}
      </Flex>
    </Fragment>
  );
};

Choice.propTypes = {
  options: PropTypes.object.isRequired,
  stepKey: PropTypes.string.isRequired,
  lineKey: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
};

Choice.defaultProps = {
  mandatory: false,
};

export default Choice;

import { Box } from '@hausgold/designsystem';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import {
  STEP_CALCULATION,
  STEP_START,
  STEP_USER_DATA,
} from 'fw-utils/CONSTANTS';
import { ApiContext, WizardContext } from 'fw-utils/contexts';
import ExtendedWizard from 'fw-components/variants/Extended/ExtendedWizard';
import sendTracking from 'fw-utils/tracking';
import CalculationStep from './CalculationStep';
import Dialog from './Dialog';
import Error from './Error';
import Skeleton from './Skeleton';
import Start from './Start';
import UserDataStep from './UserDataStep';

/**
 * The Formwizard's wizard
 *
 * This component decides what is rendered when in a global capacity (e.g.
 * Error, Skeleton, Start or Dialog).
 */
const WizardNavigation = ({ bgColor }) => {
  const {
    data,
    error,
    loading,
    dialogId,
    redirectUrl: showExtendedContent,
  } = useContext(ApiContext);
  const { currentLine, currentStep, components } = useContext(WizardContext);
  const [hasTrackedFirstInteraction, setHasTrackedFirstInteraction] = useState(
    false
  );

  // Track first user interaction
  useEffect(() => {
    if (currentLine && !hasTrackedFirstInteraction) {
      sendTracking('click_formwizard', {
        label: 'first_click',
        category: 'formwizard_interaction',
      });
      setHasTrackedFirstInteraction(true);
    }
  }, [currentLine]);

  // this is used in order to test the new clean user form for marketing
  let UserDataStepComponent;
  if (components?.UserDataStep) {
    UserDataStepComponent = components.UserDataStep;
  } else {
    UserDataStepComponent = UserDataStep;
  }

  // Skeleton loading
  if ((!data && !error) || loading) {
    return (
      <Box bg={bgColor}>
        <Skeleton />
      </Box>
    );
  }

  // Error
  if (error || !dialogId) {
    return (
      <Box bg={bgColor}>
        <Error />
      </Box>
    );
  }

  // Used for extended wizard only (Test Scenario)
  if (showExtendedContent) {
    return (
      <Box bg={bgColor}>
        <ExtendedWizard />
      </Box>
    );
  }

  // Start
  if (!currentStep || !currentLine || currentStep === STEP_START) {
    return (
      <Box bg={bgColor}>
        <Start />
      </Box>
    );
  }

  // User data step
  if (currentStep === STEP_USER_DATA) {
    return (
      <Box bg={bgColor}>
        <UserDataStepComponent />
      </Box>
    );
  }

  // Calculation / processing / loading
  if (currentStep === STEP_CALCULATION) {
    return (
      <Box bg={bgColor}>
        <CalculationStep />
      </Box>
    );
  }

  // Dialog
  if (currentLine) {
    return (
      <Box bg={bgColor}>
        <Dialog lineKey={currentLine} />
      </Box>
    );
  }

  // 404 Fallback Page
  return (
    <Box bg={bgColor}>
      <Error />
    </Box>
  );
};

WizardNavigation.propTypes = {
  bgColor: PropTypes.string,
};

WizardNavigation.defaultProps = {
  bgColor: '',
};

export default WizardNavigation;

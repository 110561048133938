import { Button, StepIndicator } from '@hausgold/designsystem';
import { ArrowLeftDrop, ArrowRightDrop } from '@hausgold/designsystem/icons';
import { useFormikContext } from 'formik';
import { FIELD_TYPE_ADDRESS, STEP_START } from 'fw-utils/CONSTANTS';
import { ApiContext, WizardContext } from 'fw-utils/contexts';
import sendTracking from 'fw-utils/tracking';
import { keys } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import {
  getBackButtonText,
  getNextButtonText,
  getNextStepKey,
  getNextStepPosition,
  getPreviousStepKey,
} from '../../services/models/dialog';

const NavigationButtons = ({
  totalSteps,
  currentStepIndex,
  stepKey,
  stepType,
}) => {
  const { data } = useContext(ApiContext);
  const {
    setCurrentStep,
    currentStep,
    currentLine,
    position,
    components: { NavigationButtonsLayoutContainer, CustomNavigationButtons },
  } = useContext(WizardContext);

  const formikContext = useFormikContext();

  const nextStepKey = getNextStepKey(data, currentLine, currentStep);
  const nextStepPosition = getNextStepPosition(data, currentLine, currentStep);
  const previousStepKey = getPreviousStepKey(data, currentLine, currentStep);

  // Trigger field errors if user clicks on "weiter"
  const touchCurrentFields = () => {
    // As address is a "flat object" all its fields must be touched separately.
    if (stepType === FIELD_TYPE_ADDRESS) {
      formikContext.setFieldTouched(`${stepKey}_zip`, true, true);
      formikContext.setFieldTouched(`${stepKey}_street`, true, true);
      formikContext.setFieldTouched(`${stepKey}_city`, true, true);

      // Normal fields handling
    } else {
      formikContext.setFieldTouched(stepKey, true, true);
    }
  };

  // Move to previous step
  const handlePreviousButtonClick = () => {
    // If we are on first step go back to start
    if (!previousStepKey) return setCurrentStep(STEP_START);
    // Else go back tp previous
    return setCurrentStep(previousStepKey);
  };

  // Handle all checks and tracking events and move to next step
  const handleNextButtonClick = (event) => {
    touchCurrentFields();

    formikContext.validateForm();

    // Regex matches the currentStep (strict) or currentStep with underscore attachment like currentStep_street.
    const regex = new RegExp(`${currentStep}(_.+)?`);

    // Check if all current fields are set. If not stop and stay on current step
    if (
      keys(formikContext.errors).some((errorField) => regex.test(errorField))
    ) {
      event.preventDefault();
      return;
    }

    // Go to next step.
    // If no step is left the final navigation is done by <Formik onSubmit/> located some files up.
    if (nextStepKey) {
      sendTracking(
        `${currentLine}-clickto_step${nextStepPosition}_${nextStepKey}`,
        {
          label: getNextButtonText(data),
          category: 'formwizard',
        }
      );
      setCurrentStep(nextStepKey);
    }
  };

  // Use custom renderer instead of origin if present
  if (CustomNavigationButtons) {
    return (
      <CustomNavigationButtons
        totalSteps={totalSteps}
        currentStepIndex={currentStepIndex}
        nextStepKey={nextStepKey}
        handlePreviousButtonClick={handlePreviousButtonClick}
        handleNextButtonClick={handleNextButtonClick}
      />
    );
  }

  /**
   * Positon based styles
   */
  let buttonMargin = {
    base: 1,
    sm: null,
    md: 4,
  };

  let buttonPaddingX = {
    base: 3.5,
    sm: 4,
    md: 10,
  };

  let buttonPaddingY = {
    base: 2.5,
    sm: 3,
  };

  let iconMargin = {
    base: 1.5,
    md: 2.5,
  };

  let indicatorMarginX = {
    base: 2.5,
    sm: 3,
    md: 4,
  };

  if (position === 'right' || position === 'top') {
    buttonMargin = {};
    buttonPaddingX = {};
    buttonPaddingY = {};

    iconMargin = {
      base: 1,
    };

    indicatorMarginX = {
      base: 2.5,
    };
  }

  return (
    <NavigationButtonsLayoutContainer>
      <Button
        variant="muted"
        as="a"
        className="back"
        mr={buttonMargin}
        px={buttonPaddingX}
        py={buttonPaddingY}
        onClick={handlePreviousButtonClick}
      >
        <ArrowLeftDrop boxSize={2.5} mr={iconMargin} />
        {getBackButtonText(data)}
      </Button>

      <StepIndicator
        colorScheme="red"
        mx={indicatorMarginX}
        flexGrow="1"
        alignItems="center"
        totalSteps={totalSteps}
        currentStep={currentStepIndex + 1}
      />

      <Button
        variant="primary"
        as={nextStepKey ? 'a' : 'button'}
        type={nextStepKey ? null : 'submit'}
        className="next"
        ml={nextStepKey ? buttonMargin : null}
        px={buttonPaddingX}
        py={buttonPaddingY}
        onClick={handleNextButtonClick}
      >
        {getNextButtonText(data)}
        <ArrowRightDrop boxSize={2.5} ml={iconMargin} />
      </Button>
    </NavigationButtonsLayoutContainer>
  );
};

NavigationButtons.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  currentStepIndex: PropTypes.number.isRequired,
  stepKey: PropTypes.string.isRequired,
  stepType: PropTypes.string.isRequired,
};

export default NavigationButtons;

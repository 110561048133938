import React from 'react';
import PropTypes from 'prop-types';
import UserDataStepLayoutContainerBase from '../../Layouts/UserDataStepLayoutContainer';

const UserDataStepLayoutContainer = ({ children, ...props }) => (
  <UserDataStepLayoutContainerBase px={4} mb={4} {...props}>
    {children}
  </UserDataStepLayoutContainerBase>
);

UserDataStepLayoutContainer.propTypes = {
  children: PropTypes.node,
};

UserDataStepLayoutContainer.defaultProps = {
  children: null,
};

export default UserDataStepLayoutContainer;

import { DesignsystemProvider } from '@hausgold/designsystem';
import { VARIANTS } from 'fw-utils/CONSTANTS';
import PropTypes from 'prop-types';
import React from 'react';
import FormwizardClean from './components/variants/Clean';
import FormwizardCTA from './components/variants/CTA';
import FormwizardCTA2 from './components/variants/CTA2';
import FormwizardDefault from './components/variants/Default';
import FormwizardExtended from './components/variants/Extended';
import FormwizardHighlighted from './components/variants/Highlighted';
import FormwizardRaffle from './components/variants/Raffle';
import defaultConfig from './defaultconfig';
import defaultTheme from './utils/theme';

/**
 * Core component entry.
 * Decides which Formwizard layout to render depending on given `variant`.
 * @param variant - Formwizard layout
 * @param dialogId - Formwizard content
 * @param recruiterId - Optional user uuid of the recruiter
 * @param leadId - Optional tracking id
 * @param theme - Optional style scheme
 * @param isLocal - Define environment context
 * @param config - The application configuration
 * @returns {JSX.Element}
 * @constructor
 */
const FormwizardChooser = React.forwardRef(
  (
    {
      variant,
      dialogId,
      recruiterId,
      dialogConfig,
      leadId,
      theme,
      config,
      isLocal,
      position,
      doTrack,
      appsignal,
    },
    ref
  ) => {
    let ComponentVariant;
    switch (variant) {
      case VARIANTS.HIGHLIGHTED:
        ComponentVariant = FormwizardHighlighted;
        break;
      case VARIANTS.CLEAN:
        ComponentVariant = FormwizardClean;
        break;
      case VARIANTS.CTA:
        ComponentVariant = FormwizardCTA;
        break;
      case VARIANTS.CTA2:
        ComponentVariant = FormwizardCTA2;
        break;
      case VARIANTS.EXTENDED:
        ComponentVariant = FormwizardExtended;
        break;
      case VARIANTS.RAFFLE:
        ComponentVariant = FormwizardRaffle;
        break;
      case VARIANTS.DEFAULT:
      default:
        ComponentVariant = FormwizardDefault;
        break;
    }

    return (
      <DesignsystemProvider resetCSS={false} theme={theme} fonts={false}>
        <ComponentVariant
          appsignal={appsignal}
          config={config}
          key={variant}
          dialogId={dialogId}
          recruiterId={recruiterId}
          dialogConfig={dialogConfig}
          leadId={leadId}
          isLocal={isLocal}
          position={position}
          ref={ref}
          variant={variant}
          doTrack={doTrack}
        />
      </DesignsystemProvider>
    );
  }
);

FormwizardChooser.propTypes = {
  dialogId: PropTypes.string,
  recruiterId: PropTypes.string,
  dialogConfig: PropTypes.object,
  leadId: PropTypes.string,
  theme: PropTypes.object,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  config: PropTypes.shape({
    api: PropTypes.shape({
      urls: PropTypes.shape({
        wizard: PropTypes.shape({
          http: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    analytics: PropTypes.shape({
      segmentKey: PropTypes.string,
    }),
  }),
  appsignal: PropTypes.object,
  isLocal: PropTypes.bool,
  doTrack: PropTypes.bool,
  position: PropTypes.oneOf(['right', 'top', 'header']),
};

FormwizardChooser.defaultProps = {
  dialogId: 'standard-01',
  recruiterId: null,
  dialogConfig: undefined,
  variant: VARIANTS.DEFAULT,
  leadId: null,
  theme: defaultTheme,
  isLocal: false,
  config: defaultConfig,
  position: null,
  doTrack: true,
  appsignal: null,
};

export default FormwizardChooser;
export { defaultConfig as FormwizardConfig };

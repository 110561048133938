import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@hausgold/designsystem';

const FluidMaxContainer = (props) => <Container px={0} {...props} />;

FluidMaxContainer.propTypes = {
  children: PropTypes.node,
};

FluidMaxContainer.defaultProps = {
  children: null,
};

export default FluidMaxContainer;

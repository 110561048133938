import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'fw-utils/contexts';
import ExtendedStart from 'fw-components/variants/Extended/ExtendedStart';
import ExtendedSteps from 'fw-components/variants/Extended/ExtendedSteps';
import { Box, Text, Spinner } from '@hausgold/designsystem';
import sendTracking from 'fw-utils/tracking';

/**
 * Steps from extended config.
 * Normally this would be generic and accessible by our model helper.
 * @type {string[]}
 */
const extendedSteps = ['current_usage', 'ownership', 'evaluation_reason'];

/**
 * Provides the entry to the extended dialog.
 * NOTE: This is for testing purpose...
 */
const ExtendedWizard = () => {
  const { redirectUrl } = useContext(ApiContext);
  const [question, setQuestion] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [stepValues, setStepValues] = useState({
    current_usage: undefined,
    ownership: undefined,
    evaluation_reason: undefined,
  });

  // Send extra event to track that lead conversion is done now.
  // This is needed as the normal "last_click" event from user_data_step is not fired for extended variant.
  useEffect(() => {
    sendTracking('initial_extended_page', {
      label: 'conversion_done',
      category: 'formwizard_additional_steps',
    });
  }, []);

  const onSendExtendedTracking = ({ currentStep, value }) => {
    sendTracking(
      // Send current step or "fallback" if on start page
      `click_${currentStep || 'additional_steps'}`,
      {
        // Value is either a specific value from card or "weiter"
        label: value,
        category: 'formwizard_additional_steps',
      }
    );
  };

  // Ensure user gets feedback if done and redirect to VP finally.
  const handleRedirect = (shouldSendTracking = false) => {
    if (shouldSendTracking) {
      onSendExtendedTracking({ currentStep: question, value: 'überspringen' });
    }

    setShowSpinner(true);

    // NOTE: Use +window.top.location+ to break out of the
    //       iframe (redirect the whole page/tab).
    window.location.href = redirectUrl;
  };

  // Handle back Button mechanic and go back to previous step or start page.
  const handleGoBack = () => {
    const currentStepPosition = extendedSteps.findIndex(
      (step) => step === question
    );
    const previousStep =
      currentStepPosition === 0 ? null : extendedSteps[currentStepPosition - 1];
    setQuestion(previousStep);
  };

  // Handle weiter/"value" Button mechanic by saving value if usefully and go to next step or call redirect if the end is reached.
  const handleGoForward = (value) => {
    // Send tracking
    onSendExtendedTracking({ currentStep: question, value });

    // If no question is set yat we are on start page and want to the first step now.
    if (!question) {
      setQuestion(extendedSteps[0]);
      return;
    }

    // Set new value to current step
    setStepValues({
      ...stepValues,
      [question]: value === 'weiter' ? null : value,
    });

    // get current step and check if a next step exists
    const currentStepPosition = extendedSteps.findIndex(
      (step) => step === question
    );
    const nextStep =
      currentStepPosition === extendedSteps.length - 1
        ? null
        : extendedSteps[currentStepPosition + 1];

    // If next step exists go on with it.
    if (nextStep) {
      setQuestion(nextStep);
      // Else we are done and call redirect function
    } else {
      handleRedirect(false);
    }
  };

  if (showSpinner) {
    return (
      <Box textAlign="center">
        <Text as="div" fontSize="xl">
          Einen Moment noch. Wir leiten Sie weiter.
        </Text>
        <Spinner />
      </Box>
    );
  }

  // If no question is selected we show the "start page" where the user can choose between go forward and skip.
  if (!question) {
    return (
      <ExtendedStart
        handleRedirect={handleRedirect}
        handleGoForward={handleGoForward}
      />
    );
  }

  // If the user choose "go forward" we guide him through the hard-coded questions
  return (
    <ExtendedSteps
      extendedSteps={extendedSteps}
      question={question}
      selectedValue={stepValues[question]}
      handleGoBack={handleGoBack}
      handleGoForward={handleGoForward}
    />
  );
};

export default ExtendedWizard;

import { createBreakpoints, extendTheme } from '@hausgold/designsystem';
import { theme as chakraTheme } from '@chakra-ui/react';

// Formwizard theme
// We use slightly different breakpoint values here, namely the `md` value is different.
const theme = extendTheme({
  colors: { red: { 400: '#e04912' } },
  breakpoints: createBreakpoints({
    sm: '576px',
    md: '725px',
    lg: '992px',
    xl: '1200px',
  }),
  components: {
    Button: {
      variants: {
        primary: () => ({
          color: 'white',
          borderRadius: 'circle',
          borderColor: 'red.400',
          bg: 'red.400',
          px: 10,
          py: 3,
          boxShadow: 'none',
        }),
        muted: () => ({
          bg: 'white',
          color: 'gray.400',
          borderColor: '#bfbec8',
          borderRadius: 'circle',
          px: 14,
          py: 6,
          boxShadow: 'none',
          _hover: {
            color: 'gray.400',
          },
        }),
      },
    },
    Slider: {
      ...chakraTheme.components.Slider,
      baseStyle: (props) => ({
        ...chakraTheme.components.Slider.baseStyle(props),
        thumb: {
          ...chakraTheme.components.Slider.baseStyle(props).thumb,
          bg: 'blue.400',
        },
        track: {
          ...chakraTheme.components.Slider.baseStyle(props).track,
          borderRadius: '50px',
        },
        filledTrack: {
          ...chakraTheme.components.Slider.baseStyle(props).filledTrack,
          bg: 'blue.400',
        },
      }),
      sizes: {
        ...chakraTheme.components.Slider.sizes,
        lg: () => ({
          track: {
            h: '8px',
          },
        }),
      },
    },
    Tooltip: {
      ...chakraTheme.components.Tooltip,
    },
    Radio: {
      baseStyle: {
        label: {
          color: 'gray.500',
        },
      },
    },
  },
});

export default theme;

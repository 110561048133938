import React from 'react';
import PropTypes from 'prop-types';
import { Box, useToken } from '@hausgold/designsystem';

/**
 * Dynamically returns a fitting icon component from an icon ID
 * @param {string} iconSvg String representation of an SVG.
 * @param {string} color DS Theme color code the icon should use as variable color.
 * @param {string} w Width style prop the icon should have.
 * @param {string} h Height style prop the icon should have.
 * @param {Object} props Additional style props the icon container can have.
 */
const DynamicIcon = ({ iconSvg, color, w, h, ...props }) => {
  // Get hex of given color or use default one.
  const iconColor = useToken('colors', color, 'colors.blue.400');

  // Return nothing if svg isn't set
  if (!iconSvg) {
    return null;
  }

  const { iconId, ...boxProps } = props;

  // Replace "auto" on height and width with "100%" as "auto" is not aligned
  const transformAuto = (dimension) =>
    dimension === 'auto' ? '100%' : dimension;

  // Format svg to our needs
  const svg = iconSvg
    // Replace "placeholder" colors with specific one.
    // "currentColor" seems not to work with the implementation, so we set the correct color right away.
    .replace(/#1098F3/g, iconColor)
    .replace(/#1694F4/g, iconColor)
    .replace(/#0071B9/g, iconColor)
    .replace(/currentColor/g, iconColor)
    // Overwrite svg props with given styles
    // Example svg tag: <svg width="24px" height="24px">...</svg>
    .replace(/width="[^"]*"/, `width=${transformAuto(w)}`)
    .replace(/height="[^"]*"/, `height=${transformAuto(h)}`);

  // Render raw html (svg)
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: svg }}
      display="inline-block"
      lineHeight="1em"
      {...boxProps}
    />
  );
};

DynamicIcon.propTypes = {
  iconSvg: PropTypes.string,
  iconId: PropTypes.string,
  color: PropTypes.string,
  w: PropTypes.string,
  h: PropTypes.string,
};

DynamicIcon.defaultProps = {
  iconSvg: null,
  iconId: null,
  color: null,
  // Defaults of every Icon
  w: '24px',
  h: '24px',
};

export default DynamicIcon;

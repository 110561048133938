import React from 'react';
import PropTypes from 'prop-types';
import { Col } from '@hausgold/designsystem';

const ChoiceOptionColContainer = ({ children, position, ...props }) => {
  let colWidth = {
    base: 1 / 2,
    sm: 1 / 4,
  };

  /**
   * Special breakpoints for variants
   */
  if (position === 'right' || position === 'top') {
    colWidth = {
      base: 1 / 2,
      sm: 1 / 4,
      md: 1 / 2,
    };
  }

  return (
    <Col mb={4} pl={4} width={colWidth} {...props}>
      {children}
    </Col>
  );
};

ChoiceOptionColContainer.propTypes = {
  children: PropTypes.node,
  position: PropTypes.oneOf(['right', 'top', 'header']),
};

ChoiceOptionColContainer.defaultProps = {
  children: null,
  position: 'header',
};

export default ChoiceOptionColContainer;

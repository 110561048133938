import React from 'react';
import PropTypes from 'prop-types';
import StartLayoutContainerBase from '../../Layouts/StartLayoutContainer';

const StartLayoutContainer = ({ children, ...props }) => (
  <StartLayoutContainerBase px={4} mb={4} {...props}>
    {children}
  </StartLayoutContainerBase>
);

StartLayoutContainer.propTypes = {
  children: PropTypes.node,
};

StartLayoutContainer.defaultProps = {
  children: null,
};

export default StartLayoutContainer;

import Appsignal from '@appsignal/javascript';
import { config, isRemote } from 'app-config';
import { plugin as breadcrumbsNetworkPlugin } from '@appsignal/plugin-breadcrumbs-network';
import { plugin as breadcrumbsConsolePlugin } from '@appsignal/plugin-breadcrumbs-console';
import { plugin as pathDecoratorPlugin } from '@appsignal/plugin-path-decorator';
import { isBrowser } from 'app-utils/isEnv';

/*
 * Make sure AppSignal is deactivated locally (and server-side), even when using
 * different app or API configurations. If not, use the usual config.
 */
const IS_ACTIVE = isBrowser && isRemote;

const appsignal = IS_ACTIVE
  ? new Appsignal({
      key: config.appsignal.key,
      revision: config.deployment.revision,
      namespace: config.deployment.branch,
    })
  : null;

/*
 * Deactivate any plugins if AppSignal is not active. The plugins obfuscate
 * where `console.log`'s came from by hijacking it.
 */
if (IS_ACTIVE) {
  // Add branch to each error
  appsignal.addDecorator((span) =>
    span.setTags({
      branch: config.deployment.branch,
    })
  );

  appsignal.use(breadcrumbsNetworkPlugin());
  appsignal.use(breadcrumbsConsolePlugin());
  appsignal.use(pathDecoratorPlugin());
}

export default appsignal;

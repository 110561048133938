import React from 'react';
import {
  Input as DsInput,
  InputRightAddon,
  InputGroup,
} from '@hausgold/designsystem';

const Input = ({ unit, ...props }) => (
  <InputGroup>
    <DsInput
      bg="gray.100"
      sx={{ '&::placeholder': { color: 'gray.400' } }}
      {...props}
    />
    {
      // If a unit exists it shows it with the same style like the input has

      !!unit && (
        <InputRightAddon
          color="gray.400"
          bg="gray.100"
          fontSize="lg"
          height="3.75rem"
        >
          {unit}
        </InputRightAddon>
      )
    }
  </InputGroup>
);

Input.propTypes = {
  ...DsInput.propTypes,
};

export default Input;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@hausgold/designsystem';

const RendererLayoutContainer = ({ children, ...props }) => (
  <Box className="renderer-container" {...props}>
    {children}
  </Box>
);

RendererLayoutContainer.propTypes = {
  children: PropTypes.node,
};

RendererLayoutContainer.defaultProps = {
  children: null,
};

export default RendererLayoutContainer;

import { keys } from 'lodash-es';

/**
 * Returns the dialog's dialog object
 * @param dialog
 * @return {object|undefined}
 */
export const getDialog = (dialog) => dialog?.dialog;

/**
 * Returns the dialog's version
 * @param dialog
 * @return {number|undefined}
 */
export const getVersion = (dialog) => getDialog(dialog)?.version;

/**
 * Returns the dialog's authenticity token
 * @param dialog
 * @return {string|undefined}
 */
export const getAuthenticityToken = (dialog) =>
  getDialog(dialog)?.authenticity_token;

/**
 * Returns the dialog's text object
 * @param dialog
 * @returns {object|undefined}
 */
export const getText = (dialog) => dialog?.text;

/**
 * Returns the dialog's back button text
 * @param dialog
 * @returns {string|undefined}
 */
export const getBackButtonText = (dialog) => getText(dialog)?.back_button;

/**
 * Returns the dialog's next button text
 * @param dialog
 * @returns {string|undefined}
 */
export const getNextButtonText = (dialog) => getText(dialog)?.next_button;

/**
 * Returns the dialog's heading
 * @param dialog
 * @returns {string|undefined}
 */
export const getHeadingText = (dialog) => getText(dialog)?.heading;

/**
 * Returns the dialog's spinner text
 * @param dialog
 * @return {string|undefined}
 */
export const getSpinnerText = (dialog) => getText(dialog)?.spinner;

/**
 * Returns the opt-in content's text
 * @param dialog
 * @return {string|undefined}
 */
export const getOptinContentText = (dialog) => getText(dialog)?.optin_content;

/**
 * Returns the contact description's text
 * @param dialog
 * @return {string|undefined}
 */
export const getContactDescriptionText = (dialog) =>
  getText(dialog)?.contact_description;

/**
 * Returns the additional content's text
 * @param dialog
 * @return {string|undefined}
 */
export const getAdditionalContentText = (dialog) =>
  getText(dialog)?.additional_content;

/**
 * Returns options
 * @param dialog
 * @returns {object|undefined}
 */
export const getOptions = (dialog) => dialog?.options;

/**
 * Returns the dialog's mandatory field options object
 * @param dialog
 * @return {object|undefined}
 */
export const getMandatoryFieldOptions = (dialog) =>
  getOptions(dialog)?.mandatory_fields;

/**
 * Returns the spinner duration
 * @param dialog
 * @returns {number|undefined}
 */
export const getSpinnerDuration = (dialog) =>
  getOptions(dialog)?.spinner?.duration;

/**
 * Returns all lines
 * @param dialog
 * @returns {object|undefined}
 */
export const getLines = (dialog) => dialog?.lines;

/**
 * Returns all line keys
 * @param dialog
 * @return {string[]}
 */
export const getLineKeys = (dialog) => keys(getLines(dialog));

/**
 * Returns a line
 * @param dialog
 * @param lineKey
 * @returns {object|undefined}
 */
export const getLine = (dialog, lineKey) => getLines(dialog)?.[lineKey];

/**
 * Returns a line's title
 * @param dialog
 * @param lineKey
 * @returns {string|undefined}
 */
export const getLineTitle = (dialog, lineKey) =>
  getLine(dialog, lineKey)?.title;

/**
 * Returns a line's icon name
 * @param dialog
 * @param lineKey
 * @returns {string|undefined}
 */
export const getLineIcon = (dialog, lineKey) => getLine(dialog, lineKey)?.icon;

/**
 * Returns a line's icon as plain svg string
 * @param dialog
 * @param lineKey
 * @returns {string|undefined}
 */
export const getLineIconSVG = (dialog, lineKey) =>
  getLine(dialog, lineKey)?.icon_svg;

/**
 * Returns the steps from a line (key)
 * @param dialog
 * @param lineKey
 * @returns {object|undefined}
 */
export const getSteps = (dialog, lineKey) => getLine(dialog, lineKey)?.steps;

/**
 * Returns the step from a line (key) and step (key)
 * @param dialog
 * @param lineKey
 * @param stepKey
 * @returns {object|undefined}
 */
export const getStep = (dialog, lineKey, stepKey) =>
  getSteps(dialog, lineKey)?.[stepKey];

/**
 * Returns an array of step keys from a line (key)
 * @param dialog
 * @param lineKey
 * @returns {string[]|undefined}
 */
export const getStepKeys = (dialog, lineKey) => keys(getSteps(dialog, lineKey));

/**
 * Returns the previous step key
 * @param dialog
 * @param lineKey
 * @param stepKey
 * @returns {string|undefined}
 */
export const getPreviousStepKey = (dialog, lineKey, stepKey) => {
  const stepKeys = getStepKeys(dialog, lineKey);

  return stepKeys[stepKeys.indexOf(stepKey) - 1];
};

/**
 * Returns the next step key
 * @param dialog
 * @param lineKey
 * @param stepKey
 * @returns {string|undefined}
 */
export const getNextStepKey = (dialog, lineKey, stepKey) => {
  const stepKeys = getStepKeys(dialog, lineKey);

  return stepKeys[stepKeys.indexOf(stepKey) + 1];
};

/**
 * Returns the next step position
 * @param dialog
 * @param lineKey
 * @param stepKey
 * @returns {string|undefined}
 */
export const getNextStepPosition = (dialog, lineKey, stepKey) => {
  const nextStepKey = getNextStepKey(dialog, lineKey, stepKey);
  const nextStep = getStep(dialog, lineKey, nextStepKey);

  return nextStep?.position;
};

/**
 * Returns a boolean on if navigation buttons should be shown
 * @param dialog
 * @returns {boolean|undefined}
 */
export const shouldShowNavigationButtons = (dialog) =>
  getOptions(dialog)?.back_next_buttons;

/**
 * Returns the opt-in type
 * @param dialog
 * @return {string|undefined}
 */
export const getOptinType = (dialog) => getOptions(dialog)?.optin_type;

import React, { useContext } from 'react';
import { Text, Box } from '@hausgold/designsystem';
import { WizardContext } from 'fw-utils/contexts';
import FluidMaxContainer from '../components/FluidMaxContainer';

const Error = () => {
  const {
    components: { WizardHeading },
  } = useContext(WizardContext);

  return (
    <Box textAlign="center">
      <WizardHeading>Es tut uns leid!</WizardHeading>
      <FluidMaxContainer>
        <Text>
          Es ist ein Fehler aufgetreten. Bitte versuchen Sie es in ein paar
          Minuten erneut.
        </Text>
      </FluidMaxContainer>
    </Box>
  );
};

export default Error;

import React from 'react';
import PropTypes from 'prop-types';
import CalculationLayoutContainerBase from '../../Layouts/CalculationLayoutContainer';

const CalculationLayoutContainer = ({ children, ...props }) => (
  <CalculationLayoutContainerBase px={4} my={4} {...props}>
    {children}
  </CalculationLayoutContainerBase>
);

CalculationLayoutContainer.propTypes = {
  children: PropTypes.node,
};

CalculationLayoutContainer.defaultProps = {
  children: null,
};

export default CalculationLayoutContainer;

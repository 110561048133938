import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import { Feedback, Flex, Box, Text } from '@hausgold/designsystem';
import { ApiContext, WizardContext } from 'fw-utils/contexts';
import Input from '../../../components/Input';
import {
  getNextStepKey,
  getNextStepPosition,
} from '../../../services/models/dialog';

/**
 * Renders an address block containing of the 3 fields street (including number), zip code and city.
 * @param mandatory
 * @param placeholderStreet
 * @param placeholderZip
 * @param placeholderCity
 * @param stepKey
 * @param lineKey
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Address = ({
  mandatory,
  placeholderStreet,
  placeholderZip,
  placeholderCity,
  stepKey,
  lineKey,
  ...props
}) => {
  const [fieldStreet, metaStreet, helpersStreet] = useField(
    `${stepKey}_street`
  );
  const [fieldZip, metaZip, helpersZip] = useField(`${stepKey}_zip`);
  const [fieldCity, metaCity, helpersCity] = useField(`${stepKey}_city`);
  const formikContext = useFormikContext();

  const { data } = useContext(ApiContext);
  const { currentStep, setCurrentStep, currentLine } = useContext(
    WizardContext
  );

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      helpersStreet.setTouched(true);
      helpersZip.setTouched(true);
      helpersCity.setTouched(true);
      formikContext.validateForm();
      event.preventDefault();

      // TODO: Apparently, even with the call to validateForm, formik's
      //   internal state (meta) hasn't gotten the info yet that a field
      //   has an error at this point, so we manually at least check for
      //   non-empty. Optimally, this should work with formik's
      //   validation.
      // (Copy from other inputs)
      if (
        metaStreet.value?.length &&
        metaZip.value?.length &&
        metaCity.value?.length
      ) {
        const nextStepKey = getNextStepKey(data, currentLine, currentStep);

        // If there is no next step key, submit the form.
        if (!nextStepKey) {
          // Track user progress (last Step; Next is "Kontaktformular").
          window?.analytics?.track(
            `${currentLine}-clickto_step_Kontaktformular`,
            {
              label: 'Enter',
              category: 'formwizard',
            }
          );
          formikContext.submitForm();
          // Else go to next step.
        } else {
          const nextStepPosition = getNextStepPosition(data, lineKey, stepKey);
          // Track user progress
          window?.analytics?.track(
            `${lineKey}-clickto_step${nextStepPosition}_${nextStepKey}`,
            {
              label: event.target.value,
              category: 'formwizard',
            }
          );
          setCurrentStep(nextStepKey);
        }
      }
    }
  };

  return (
    <Fragment>
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        gridRowGap={4}
        // same maxWidth as in KIT
        maxWidth="764px"
        mx="auto"
        onKeyDown={handleEnter}
      >
        <Box width={{ base: '100%', sm: '498px' }} textAlign="center" mx={4}>
          <Input
            // Street
            {...fieldStreet}
            type="text"
            inputMode="text"
            isInvalid={Boolean(metaStreet.touched && metaStreet.error)}
            size="lg"
            placeholder={`${placeholderStreet || ''} ${mandatory ? '*' : ''}`}
            onKeyDown={(event) => {
              // Only allow special (some) characters
              if (
                !/[\wäÄöÖüÜß \-0-9]/.test(event.key) &&
                event.key !== 'Backspace' &&
                event.key !== 'Delete' &&
                // Allow hotkeys (CTRL+C, CTRL+V)
                !event.ctrlKey &&
                !event.altKey &&
                // Allow mac's hotkeys (CMD+C, CMD+V)
                !event.metaKey &&
                // Allow left and right arrow keys
                event.key !== 'ArrowLeft' &&
                event.key !== 'ArrowRight'
              ) {
                event.preventDefault();
              }
            }}
            {...props}
          />
          <Flex mt={2} justifyContent="space-between">
            <ErrorMessage name={`${stepKey}_street`}>
              {(message) => (
                <Feedback mb={0} mx="auto" display="block">
                  {message}
                </Feedback>
              )}
            </ErrorMessage>

            {mandatory && !(metaStreet.touched && metaStreet.error) && (
              // Keep height space to minimize layout shift on error message
              <Text as="span" ml="auto" color="gray.400" mb={0}>
                &nbsp;
              </Text>
            )}
          </Flex>
        </Box>

        <Box mx={4} width={{ base: '100%', sm: 'inherit' }}>
          <Input
            // Zip Code
            {...fieldZip}
            type="text"
            inputMode="numeric"
            isInvalid={Boolean(metaZip.touched && metaZip.error)}
            width="100%"
            size="lg"
            placeholder={`${placeholderZip || ''} ${mandatory ? '*' : ''}`}
            onKeyDown={(event) => {
              // Only allow special (some) characters
              if (
                !/[0-9]/.test(event.key) &&
                event.key !== 'Backspace' &&
                event.key !== 'Delete' &&
                // Allow hotkeys (CTRL+C, CTRL+V)
                !event.ctrlKey &&
                !event.altKey &&
                // Allow mac's hotkeys (CMD+C, CMD+V)
                !event.metaKey &&
                // Allow left and right arrow keys
                event.key !== 'ArrowLeft' &&
                event.key !== 'ArrowRight'
              ) {
                event.preventDefault();
              }
            }}
            {...props}
          />
          <Flex mt={2} justifyContent="space-between">
            <ErrorMessage name={`${stepKey}_zip`}>
              {(message) => (
                <Feedback mb={0} mx="auto" display="block">
                  {message}
                </Feedback>
              )}
            </ErrorMessage>

            {mandatory && !(metaZip.touched && metaZip.error) && (
              // Keep height space to city's mandatory field
              <Text as="span" ml="auto" color="gray.400" mb={0}>
                &nbsp;
              </Text>
            )}
          </Flex>
        </Box>

        <Box mx={4} width={{ base: '100%', sm: 'inherit' }}>
          <Input
            // City
            {...fieldCity}
            type="text"
            inputMode="text"
            isInvalid={Boolean(metaCity.touched && metaCity.error)}
            width="100%"
            size="lg"
            placeholder={`${placeholderCity || ''} ${mandatory ? '*' : ''}`}
            onKeyDown={(event) => {
              // Only allow special (some) characters
              if (
                !/[\wäÄöÖüÜß-]/.test(event.key) &&
                event.key !== 'Backspace' &&
                event.key !== 'Delete' &&
                // Allow hotkeys (CTRL+C, CTRL+V)
                !event.ctrlKey &&
                !event.altKey &&
                // Allow mac's hotkeys (CMD+C, CMD+V)
                !event.metaKey &&
                // Allow left and right arrow keys
                event.key !== 'ArrowLeft' &&
                event.key !== 'ArrowRight'
              ) {
                event.preventDefault();
              }
            }}
            {...props}
          />
          <Flex mt={2} justifyContent="space-between">
            <ErrorMessage name={`${stepKey}_city`}>
              {(message) => (
                <Feedback mb={0} mx="auto" display="block">
                  {message}
                </Feedback>
              )}
            </ErrorMessage>

            {mandatory && !(metaCity.touched && metaCity.error) && (
              <Text as="span" ml="auto" color="gray.400" mb={0}>
                * Pflichtfeld
              </Text>
            )}
          </Flex>
        </Box>
      </Flex>
    </Fragment>
  );
};

Address.propTypes = {
  mandatory: PropTypes.bool,
  placeholderStreet: PropTypes.string,
  placeholderZip: PropTypes.string,
  placeholderCity: PropTypes.string,
  stepKey: PropTypes.string.isRequired,
  lineKey: PropTypes.string.isRequired,
};

Address.defaultProps = {
  mandatory: false,
  placeholderStreet: null,
  placeholderZip: null,
  placeholderCity: null,
};

export default Address;

import { Box, Button, Flex, Text } from '@hausgold/designsystem';
import React, { useContext, useState } from 'react';
import { ApiContext, WizardContext } from 'fw-utils/contexts';
import { getNextButtonText } from '../../../services/models/dialog';
import ProgressBarCTA from './ProgressBarCTA';

const CustomNavigationButtonsStart = () => {
  const { data } = useContext(ApiContext);
  const {
    components: { NavigationButtonsLayoutContainer },
  } = useContext(WizardContext);

  const [showMessage, setShowMessage] = useState(false);

  return (
    <NavigationButtonsLayoutContainer flexDirection="column">
      <Box textAlign="center" visibility={showMessage ? 'block' : 'hidden'}>
        <Text mb={0} color="red.400">
          Bitte wählen Sie eine Immobilie aus.
        </Text>
      </Box>
      <Box width="100%">
        <Flex width="100%" justifyContent="flex-end" mb={4}>
          <Button
            colorScheme="red"
            backgroundColor="rgb(244, 99, 100)"
            borderColor="rgb(244, 99, 100)"
            className="next"
            // this is used in order to not have the button stuck in :focus state
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => {
              setShowMessage(true);
            }}
          >
            {getNextButtonText(data)}
          </Button>
        </Flex>

        <ProgressBarCTA />
      </Box>
    </NavigationButtonsLayoutContainer>
  );
};

export default CustomNavigationButtonsStart;

import { omit, replace } from 'lodash-es';

/**
 * Path
 *
 * Auto-replaces parameters in the path, e.g. `/path/with/{param}`, with the
 * given arguments.
 */
class ArgumentPath {
  // Raw, unprocessed path
  raw = '';

  // Arguments
  args = {};

  // The regular expression for parameters in path, e.g. `/path/with/{param}`.
  PARAM_REGEX = /{.*?}/g;

  constructor({ path, args, isLocal = true }) {
    if (typeof path !== 'string' || !path.length) {
      throw new Error('Given path is empty or not a string.');
    }

    this.raw = path;
    this.args = args;
    this.isLocal = isLocal;

    // Check for errors & warn about them locally
    if (this.hasParameters && isLocal) {
      this.allParameters.forEach((parameterName) => {
        if (!args[parameterName]) {
          console.warn(
            `The path "${path}" has a parameter in it, but it can't be replaced because "${parameterName}" is not in the arguments.`
          );
        }

        if (
          !(
            typeof args[parameterName] === 'string' ||
            typeof args[parameterName] === 'number'
          )
        ) {
          console.warn(
            `The path "${path}" has a parameter in it, but the argument "${parameterName}" is not a string or a number.`
          );
        }
      });
    }
  }

  // Returns true if the path contains replaceable parameters
  get hasParameters() {
    return !!this.raw.match(this.PARAM_REGEX);
  }

  // All parameters as an array
  get allParameters() {
    return this.getParametersFromPath(this.raw);
  }

  // Returns the processed path with the given arguments
  get processed() {
    return this.replaceParametersWith(this.raw, this.args);
  }

  // Arguments without parameters
  get argsWithoutParameters() {
    return this.removeParametersFromArgs(this.raw, this.args);
  }

  /**
   * Replaces parameters in a path with the given argument, e.g. `/property/{id}` becomes `/property/123-456-789`.
   * Parameters are considered required. If the parameter was not given, an error is thrown.
   * @param {string} path
   * @param {object} args
   */
  replaceParametersWith = (path, args) =>
    replace(path, this.PARAM_REGEX, (foundParameter) => {
      const parameterName = foundParameter.replace('{', '').replace('}', '');

      return args[parameterName];
    });

  /**
   * Gets all parameter names from a path - as an array
   * @param {string} path
   * @returns [string]
   */
  getParametersFromPath = (path) =>
    path
      .match(this.PARAM_REGEX)
      .map((foundParameter) =>
        foundParameter.replace('{', '').replace('}', '')
      );

  /**
   * Removes the parameters used in the path from the args object
   * @param {string} path
   * @param {object} args
   * @returns {object}
   */
  removeParametersFromArgs = (path, args) =>
    omit(args, this.getParametersFromPath(path));
}

export default ArgumentPath;

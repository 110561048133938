import React from 'react';
import Formwizard from '../../../wizard';
import CustomNavigationButtons from './CustomNavigationButtons';
import CustomNavigationButtonsStart from './CustomNavigationButtonsStart';
import Layout from './Layout';
import ProgressBarCTA from './ProgressBarCTA';
import CustomWizardCard from './CustomWizardCard';

const CTA2 = React.forwardRef(({ ...props }, ref) => (
  <Layout ref={ref}>
    <Formwizard
      components={{
        ProgressBarCTA,
        CustomNavigationButtons,
        CustomNavigationButtonsStart,
        CustomWizardCard,
      }}
      {...props}
    />
  </Layout>
));

export default CTA2;

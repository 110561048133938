import DynamicIcon from './Functional/DynamicIcon';
import FluidMaxContainer from './FluidMaxContainer';
import Input from './Input';
import WizardCard from './WizardCard';
import WizardHeading from './WizardHeading';
import StartLayoutContainer from './Layouts/StartLayoutContainer';
import UserDataStepLayoutContainer from './Layouts/UserDataStepLayoutContainer';
import RendererLayoutContainer from './Layouts/RendererLayoutContainer';
import NavigationButtonsLayoutContainer from './Layouts/NavigationButtonsLayoutContainer';
import CalculationLayoutContainer from './Layouts/CalculationLayoutContainer';
import ChoiceOptionColContainer from './Layouts/ChoiceOptionColContainer';
import ChoiceOptionRowContainer from './Layouts/ChoiceOptionRowContainer';

/**
 * Provides all base Components
 */
export const components = {
  DynamicIcon,
  FluidMaxContainer,
  Input,
  WizardCard,
  WizardHeading,
  StartLayoutContainer,
  UserDataStepLayoutContainer,
  RendererLayoutContainer,
  NavigationButtonsLayoutContainer,
  CalculationLayoutContainer,
  ChoiceOptionColContainer,
  ChoiceOptionRowContainer,
};

export const getComponents = (customComponents) => ({
  ...components,
  ...customComponents,
});

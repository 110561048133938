import React from 'react';
import PropTypes from 'prop-types';
import { HeadingH1 } from '@hausgold/designsystem';

const WizardHeading = ({ children, ...props }) => (
  <HeadingH1
    as="div"
    textAlign="center"
    bg="blue.400"
    color="white"
    py={2.5}
    px={2}
    borderTopLeftRadius={4}
    borderTopRightRadius={4}
    mb={6}
    {...props}
  >
    {children}
  </HeadingH1>
);

WizardHeading.propTypes = {
  children: PropTypes.node,
};

WizardHeading.defaultProps = {
  children: null,
};

export default WizardHeading;

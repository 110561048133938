import React from 'react';
import { Flex } from '@hausgold/designsystem';
import TrustBox from '../../TrustBox';

/**
 * Adds a Footer including Trustpilot Widget.
 */
const Footer = ({ ...props }) => (
  <Flex
    justifyContent="center"
    backgroundColor="rgba(217,218,222,.3)"
    py={3}
    borderTopWidth={1}
    borderTopStyle="solid"
    borderTopColor="#ccc"
    borderTopLeftRadius={4}
    borderTopRightRadius={4}
    // prevent content layout shift when loading TrustBox Widget is done
    minHeight="120px"
    {...props}
  >
    <TrustBox />
  </Flex>
);

export default Footer;

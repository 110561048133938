import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { FIELD_TYPE_TEXT } from 'fw-utils/CONSTANTS';
import { ApiContext, WizardContext } from 'fw-utils/contexts';
import Input from '../../../components/Input';
import {
  getNextStepKey,
  getNextStepPosition,
} from '../../../services/models/dialog';

const Default = ({
  name,
  type,
  className,
  placeholder,
  onKeyDown,
  stepKey,
  lineKey,
  unit,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const formikContext = useFormikContext();

  const { data } = useContext(ApiContext);
  const { currentStep, setCurrentStep, currentLine } = useContext(
    WizardContext
  );

  return (
    <Fragment>
      <Input
        {...field}
        type={type}
        isInvalid={Boolean(meta.touched && meta.error)}
        textAlign="center"
        width="100%"
        size="lg"
        unit={unit}
        placeholder={placeholder}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            helpers.setTouched(true);
            helpers.setValue(event.target.value);
            formikContext.validateForm();
            event.preventDefault();

            // TODO: Apparently, even with the call to validateForm, formik's
            //   internal state (meta) hasn't gotten the info yet that a field
            //   has an error at this point, so we manually at least check for
            //   non-empty. Optimally, this should work with formik's
            //   validation.
            if (meta.value.length) {
              const nextStepKey = getNextStepKey(
                data,
                currentLine,
                currentStep
              );

              // If there is no next step key, submit the form.
              if (!nextStepKey) {
                // Track user progress (last Step; Next is "Kontaktformular").
                window?.analytics?.track(
                  `${currentLine}-clickto_step_Kontaktformular`,
                  {
                    label: 'Enter',
                    category: 'formwizard',
                  }
                );
                formikContext.submitForm();
                // Else go to next step.
              } else {
                const nextStepPosition = getNextStepPosition(
                  data,
                  lineKey,
                  stepKey
                );
                // Track user progress
                window?.analytics?.track(
                  `${lineKey}-clickto_step${nextStepPosition}_${nextStepKey}`,
                  {
                    label: event.target.value,
                    category: 'formwizard',
                  }
                );
                setCurrentStep(nextStepKey);
              }
            }
          }

          if (onKeyDown) {
            onKeyDown(event);
          }
        }}
        {...props}
      />
    </Fragment>
  );
};

Default.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onKeyDown: PropTypes.func,
  stepKey: PropTypes.string.isRequired,
  lineKey: PropTypes.string.isRequired,
  unit: PropTypes.string,
};

Default.defaultProps = {
  type: FIELD_TYPE_TEXT,
  placeholder: null,
  className: '',
  onKeyDown: null,
  unit: null,
};

export default Default;

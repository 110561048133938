import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Feedback, Flex, Text } from '@hausgold/designsystem';
import { ErrorMessage, useField } from 'formik';
import { FIELD_TYPE_NUMERICAL } from 'fw-utils/CONSTANTS';
import Default from './Default';

/**
 * Handles numerical input fields like plain number and postal code.
 * @param stepKey
 * @param lineKey
 * @param type
 * @param unit
 * @param placeholder
 * @param mandatory
 * @param options
 * @returns {JSX.Element}
 * @constructor
 */
const Number = ({
  stepKey,
  lineKey,
  type,
  unit,
  placeholder,
  mandatory,
  options,
}) => {
  const [, meta] = useField(stepKey);

  return (
    <Fragment>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <div>
          <Default
            type="text"
            onKeyDown={(event) => {
              if (
                !/[0-9]/.test(event.key) &&
                event.key !== 'Backspace' &&
                event.key !== 'Delete' &&
                // Allow hotkeys (CTRL+C, CTRL+V)
                !event.ctrlKey &&
                !event.altKey &&
                // Allow mac's hotkeys (CMD+C, CMD+V)
                !event.metaKey &&
                // Allow left and right arrow keys
                event.key !== 'ArrowLeft' &&
                event.key !== 'ArrowRight'
              ) {
                event.preventDefault();
              }
            }}
            inputMode="numeric"
            name={stepKey}
            // only show unit on numerical input
            unit={type === FIELD_TYPE_NUMERICAL ? unit : null}
            {...options}
            stepKey={stepKey}
            lineKey={lineKey}
            placeholder={`${placeholder || ''} ${mandatory ? '*' : ''}`}
          />
          <Flex mt={2} justifyContent="space-between">
            <ErrorMessage name={stepKey}>
              {(message) => (
                <Feedback mb={0} mx="auto" display="block">
                  {message}
                </Feedback>
              )}
            </ErrorMessage>

            {mandatory && !(meta.touched && meta.error) && (
              <Text as="span" ml="auto" color="gray.400" mb={0}>
                * Pflichtfeld
              </Text>
            )}
          </Flex>
        </div>
      </Flex>
    </Fragment>
  );
};

Number.propTypes = {
  mandatory: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  unit: PropTypes.string,
  stepKey: PropTypes.string.isRequired,
  lineKey: PropTypes.string.isRequired,
  options: PropTypes.object,
};

Number.defaultProps = {
  unit: null,
  placeholder: null,
  mandatory: false,
  options: {},
};

export default Number;

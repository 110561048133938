// Provides minimal necessary production config
// Partly copied from root config file
const defaultConfig = {
  url: 'https://formwizard.hausgold.de',
  api: {
    urls: {
      wizard: {
        http: 'https://formwizard-api.hausgold.de',
      },
    },
  },
  analytics: {
    segmentKey: 'KfG4s36wg3bVv2j5jsgHQEaN5ynN4njL',
  },
};

export default defaultConfig;

import { Box, Button, Flex, StepIndicator } from '@hausgold/designsystem';
import {
  ArrowLeftDrop,
  ArrowRightDrop,
  CheckCircled,
} from '@hausgold/designsystem/icons';
import FluidMaxContainer from 'fw-components/FluidMaxContainer';
import NavigationButtonsLayoutContainer from 'fw-components/Layouts/NavigationButtonsLayoutContainer';
import RendererLayoutContainer from 'fw-components/Layouts/RendererLayoutContainer';
import WizardHeading from 'fw-components/WizardHeading';
import { ApiContext, WizardContext } from 'fw-utils/contexts';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

/**
 * Show headline, options and navigation buttons for current step.
 * @param extendedSteps
 * @param question
 * @param handleGoBack
 * @param handleGoForward
 * @param selectedValue
 * @returns {JSX.Element}
 * @constructor
 */
const ExtendedSteps = ({
  extendedSteps,
  question,
  handleGoBack,
  handleGoForward,
  selectedValue,
}) => {
  const { data } = useContext(ApiContext);
  const { position } = useContext(WizardContext);

  /**
   * Positon based styles.
   * Copy from src/formwizard/src/wizard/Dialog/NavigationButtons.jsx
   */
  let buttonMargin = {
    base: 1,
    sm: null,
    md: 4,
  };

  let buttonPaddingX = {
    base: 3.5,
    sm: 4,
    md: 10,
  };

  let buttonPaddingY = {
    base: 2.5,
    sm: 3,
  };

  let iconMargin = {
    base: 1.5,
    md: 2.5,
  };

  let indicatorMarginX = {
    base: 2.5,
    sm: 3,
    md: 4,
  };

  if (position === 'right' || position === 'top') {
    buttonMargin = {};
    buttonPaddingX = {};
    buttonPaddingY = {};

    iconMargin = {
      base: 1,
    };

    indicatorMarginX = {
      base: 2.5,
    };
  }

  const hasNextStep =
    extendedSteps.findIndex((step) => step === question) <
    extendedSteps.length - 1;

  return (
    <Box>
      <WizardHeading>{data.extendedWizard.steps[question].title}</WizardHeading>
      <FluidMaxContainer>
        <RendererLayoutContainer>
          <Flex
            flexDirection="column"
            maxW="394px"
            alignItems="center"
            mx="auto"
          >
            {data.extendedWizard.steps[question].options.choices.map(
              ({ title, value }, index, origin) => (
                <Button
                  key={value}
                  variant="muted"
                  onClick={() => {
                    handleGoForward(value);
                  }}
                  flexShrink={0}
                  flexGrow={1}
                  mb={index + 1 !== origin.length ? 4 : 0}
                  isFullWidth
                  borderRadius={0}
                  fontSize="md"
                  fontWeight="normal"
                >
                  {selectedValue === value && (
                    <CheckCircled
                      top="33%"
                      right="6%"
                      position="absolute"
                      color="green.400"
                      boxSize={{ base: 6, sm: 5, md: 7 }}
                    />
                  )}
                  {title}
                </Button>
              )
            )}
          </Flex>
        </RendererLayoutContainer>
        <NavigationButtonsLayoutContainer>
          <Button
            variant="muted"
            as="a"
            className="back"
            mr={buttonMargin}
            px={buttonPaddingX}
            py={buttonPaddingY}
            onClick={handleGoBack}
          >
            <ArrowLeftDrop boxSize={2.5} mr={iconMargin} />
            Zurück
          </Button>

          <StepIndicator
            colorScheme="red"
            mx={indicatorMarginX}
            flexGrow="1"
            alignItems="center"
            totalSteps={extendedSteps.length}
            currentStep={
              extendedSteps.findIndex((step) => step === question) + 1
            }
          />

          <Button
            variant="primary"
            as={hasNextStep ? 'button' : 'a'}
            className="next"
            ml={hasNextStep ? buttonMargin : null}
            px={buttonPaddingX}
            py={buttonPaddingY}
            onClick={() => {
              handleGoForward('weiter');
            }}
          >
            Weiter
            <ArrowRightDrop boxSize={2.5} ml={iconMargin} />
          </Button>
        </NavigationButtonsLayoutContainer>
      </FluidMaxContainer>
    </Box>
  );
};

export default ExtendedSteps;

ExtendedSteps.propTypes = {
  extendedSteps: PropTypes.arrayOf(PropTypes.string).isRequired,
  question: PropTypes.string.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  handleGoForward: PropTypes.func.isRequired,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ExtendedSteps.defaultProps = {
  selectedValue: undefined,
};

import { Form, Formik } from 'formik';
import {
  FIELD_TYPE_ADDRESS,
  FIELD_TYPE_CHOICE,
  FIELD_TYPE_NUMERICAL,
  FIELD_TYPE_POSTAL,
  STEP_CALCULATION,
  STEP_USER_DATA,
} from 'fw-utils/CONSTANTS';
import { ApiContext, WizardContext } from 'fw-utils/contexts';
import sendTracking from 'fw-utils/tracking';
import { each } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import * as Yup from 'yup';
import { isIframe } from 'fw-utils/isEnv';
import {
  getLine,
  getNextButtonText,
  getSpinnerDuration,
} from '../../services/models/dialog';
import Step from './Step';

/**
 * Builds the initial <Formik> values based on the line object.
 * @param lineObject
 * @returns object
 */
const buildInitialValues = (lineObject) => {
  const initialValues = {};

  each(lineObject.steps, (step, key) => {
    // As address is an implicit "flat object" with well-defined shape we need to create each field manually.
    if (step?.type === FIELD_TYPE_ADDRESS) {
      initialValues[`${key}_street`] = '';
      initialValues[`${key}_zip`] = '';
      initialValues[`${key}_city`] = '';
    } else {
      initialValues[key] = '';
    }
  });

  return initialValues;
};

/**
 * Builds the validation schema based on the line object
 * @param lineObject
 */
const buildValidationSchema = (lineObject) => {
  const validationSchema = {};

  each(lineObject.steps, (step, key) => {
    switch (step.type) {
      case FIELD_TYPE_CHOICE: {
        let choiceSchema = Yup.string();

        if (step.options.mandatory) {
          choiceSchema = choiceSchema.required(
            'Bitte treffen Sie eine Auswahl.'
          );
        }

        validationSchema[key] = choiceSchema;
        break;
      }

      case FIELD_TYPE_ADDRESS: {
        let streetSchema = Yup.string();
        let zipSchema = Yup.string();
        let citySchema = Yup.string();

        if (step.options.mandatory) {
          streetSchema = streetSchema.required(
            'Bitte geben Sie eine Straße und Hausnummer an.'
          );
          zipSchema = zipSchema.required('Bitte geben Sie eine PLZ an.');
          citySchema = citySchema.required('Bitte geben Sie einen Ort an.');
        }

        validationSchema[`${key}_street`] = streetSchema;
        validationSchema[`${key}_zip`] = zipSchema;
        validationSchema[`${key}_city`] = citySchema;

        break;
      }

      case FIELD_TYPE_POSTAL: {
        let postalSchema = Yup.string();

        if (step.options.mandatory) {
          postalSchema = postalSchema.required('Bitte geben Sie eine PLZ an.');
        }

        validationSchema[key] = postalSchema;

        break;
      }

      case FIELD_TYPE_NUMERICAL: {
        let numberSchema = Yup.number();

        if (step.options.mandatory) {
          numberSchema = numberSchema.required('Bitte geben Sie eine Zahl an.');
        }

        if (step.options.min) {
          numberSchema = numberSchema.min(
            step.options.min,
            `Die Zahl darf nicht kleiner als ${step.options.min} sein.`
          );
        }

        if (step.options.max) {
          numberSchema = numberSchema.max(
            step.options.max,
            `Die Zahl darf nicht größer als ${step.options.max} sein.`
          );
        }

        validationSchema[key] = numberSchema;

        break;
      }

      case 'slider': {
        let sliderSchema = Yup.number();

        if (step.options.mandatory) {
          sliderSchema = sliderSchema.required('Bitte geben Sie eine Zahl an.');
        }

        validationSchema[key] = sliderSchema;
        break;
      }

      default:
        validationSchema[key] = Yup.mixed();
    }
  });

  return Yup.object().shape(validationSchema);
};

const Dialog = ({ lineKey }) => {
  const { setCurrentStep, currentLine, setStepData, currentStep } = useContext(
    WizardContext
  );
  const { data } = useContext(ApiContext);

  return (
    <Formik
      initialValues={buildInitialValues(getLine(data, currentLine))}
      validationSchema={buildValidationSchema(getLine(data, currentLine))}
      handleChange
      validateOnMount
      onSubmit={(values) => {
        setStepData(values);

        // Send a post message to our embedding host, when we have a postal
        // code filed which was filled by the user
        const postalStep = Object.entries(
          getLine(data, currentLine).steps
        ).find((cur) => cur[1].type === FIELD_TYPE_POSTAL);
        if (isIframe && postalStep) {
          const postalStepName = postalStep[0];
          const postalValue = values[postalStepName];

          if (postalValue) {
            window.parent.postMessage(
              {
                type: 'postal_code_entered',
                target: '#hausgold-wizard',
                postalCode: postalValue,
              },
              '*'
            );
          }
        }

        // Track user progress (last Step; Next is CALCULATION_STEP / "Kontaktformular").
        sendTracking(`${currentLine}-clickto_step_Kontaktformular`, {
          label: getNextButtonText(data),
          category: 'formwizard',
        });

        if (getSpinnerDuration(data) === 0) {
          setCurrentStep(STEP_USER_DATA);
        } else {
          setCurrentStep(STEP_CALCULATION);
        }
      }}
    >
      <Form>
        <Step stepKey={currentStep} lineKey={lineKey} />
      </Form>
    </Formik>
  );
};

Dialog.propTypes = {
  lineKey: PropTypes.string,
};

Dialog.defaultProps = {
  lineKey: '',
};

export default Dialog;

import React from 'react';
import { FIELD_TYPE_CHOICE } from 'fw-utils/CONSTANTS';
import Layout from '../Default/Layout';
import Formwizard from '../../../wizard';

const extendedContent = {
  extendedWizard: {
    steps: {
      current_usage: {
        position: 1,
        title: 'Wie wird die Immobilie zur Zeit genutzt?',
        type: FIELD_TYPE_CHOICE,
        options: {
          as: 'buttons',
          choices: [
            {
              title: 'Selbst bewohnt',
              value: 'Selbst bewohnt',
            },
            {
              title: 'Vermietet',
              value: 'Vermietet',
            },
            {
              title: 'Leerstehend',
              value: 'Leerstehend',
            },
          ],
        },
      },
      ownership: {
        position: 2,
        title: 'Sind Sie der Eigentümer der Immobilie?',
        type: FIELD_TYPE_CHOICE,
        options: {
          as: 'buttons',
          choices: [
            {
              title: 'Alleiniger Eigentümer',
              value: 'Alleiniger Eigentümer',
            },
            {
              title: 'Teil-Eigentümer',
              value: 'Teil-Eigentümer',
            },
            {
              title: 'Angehöriger',
              value: 'Angehöriger',
            },
            {
              title: 'kein Eigentümer',
              value: 'kein Eigentümer',
            },
          ],
        },
      },
      evaluation_reason: {
        position: 3,
        title: 'Wofür benötigen Sie den Immobilienwert?',
        type: FIELD_TYPE_CHOICE,
        options: {
          as: 'buttons',
          choices: [
            {
              title: 'Verkauf',
              value: 'Verkauf',
            },
            {
              title: 'Kauf',
              value: 'Kauf',
            },
            {
              title: 'Vermietung',
              value: 'Vermietung',
            },
            {
              title: 'Miete',
              value: 'Miete',
            },
          ],
        },
      },
    },
  },
};

/**
 * Important: This is not a variant actually but a new/enhanced dialog line.
 * So the only argument this form exists is for testing purpose as this is the "easiest" way.
 * As soon if we decide to maintain enhanced dialog lines we need a full rewrite to move it out of variants.
 *
 * This said I tried to keep the original code as separated as possible from this
 * so even if I copied a bunch of code from components here I simplified and slightly adjusted most of it
 * to fit in here.
 */
const Default = React.forwardRef(({ ...props }, ref) => (
  <Layout ref={ref}>
    <Formwizard {...props} extendedContent={extendedContent} />
  </Layout>
));

export default Default;

import React from 'react';
import Layout from './Layout';
import Formwizard from '../../../wizard';

const Default = React.forwardRef(({ ...props }, ref) => (
  <Layout ref={ref}>
    <Formwizard {...props} />
  </Layout>
));

export default Default;

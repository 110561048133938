import { Box, ProgressBar } from '@hausgold/designsystem';
import React from 'react';
import PropTypes from 'prop-types';

// This component reders a Progress bar with an initial fill value
// the remaining bar gets filled based on the steps of the selected dialog
const ProgressBarCTA = ({ currentStepIndex, totalSteps }) => {
  const initialValue = 10;
  const fillPercentage =
    ((currentStepIndex + 1) / totalSteps) * (100 - initialValue) + initialValue;

  return (
    <Box width="100%">
      <ProgressBar value={fillPercentage || initialValue} colorScheme="red" />
    </Box>
  );
};

ProgressBarCTA.propTypes = {
  currentStepIndex: PropTypes.number,
  totalSteps: PropTypes.number,
};
ProgressBarCTA.defaultProps = {
  currentStepIndex: undefined,
  totalSteps: undefined,
};

export default ProgressBarCTA;

import React from 'react';
import PropTypes from 'prop-types';
import RendererLayoutContainerBase from '../../Layouts/RendererLayoutContainer';

const RendererLayoutContainer = ({ children, ...props }) => (
  <RendererLayoutContainerBase px={4} {...props}>
    {children}
  </RendererLayoutContainerBase>
);

RendererLayoutContainer.propTypes = {
  children: PropTypes.node,
};

RendererLayoutContainer.defaultProps = {
  children: null,
};

export default RendererLayoutContainer;

import React from 'react';
import PropTypes from 'prop-types';
import NavigationButtonsLayoutContainerBase from '../../Layouts/NavigationButtonsLayoutContainer';

const NavigationButtonsLayoutContainer = ({ children, ...props }) => (
  <NavigationButtonsLayoutContainerBase px={4} mb={4} {...props}>
    {children}
  </NavigationButtonsLayoutContainerBase>
);

NavigationButtonsLayoutContainer.propTypes = {
  children: PropTypes.node,
};

NavigationButtonsLayoutContainer.defaultProps = {
  children: null,
};

export default NavigationButtonsLayoutContainer;

import useDimensions from 'react-cool-dimensions';
import { ResizeObserver } from '@juggle/resize-observer';
import { isIframe } from 'app-utils/isEnv';
import { theme } from '@hausgold/designsystem';

// Safety margin to counter-act any differences in browser rendering.
const HEIGHT_CHANGE_SAFETY_MARGIN = 10;

// Track if the function is executed for the first time.
let initial = true;

/**
 * Hook for broadcasting `formwizardHeightChange` event via `useDimensions`.
 * Should always only be used once per page.
 *
 * Note: Only works when in an `iframe`.
 *
 * Usage:
 *   const { ref } = useFormwizardHeightChange({ space: 5 });
 *   return div ref={ref} />;
 *
 * @param additionalSpace Theme space key to add to the element's height.
 * @param customHeightChange Optional "safety margin" correction value in pixel.
 * @return {{ ref }}
 */
const useFormwizardHeightChange = (additionalSpace, customHeightChange) => {
  const { observe } = useDimensions({
    useBorderBoxSize: true,
    shouldUpdate: () => isIframe,
    polyfill: ResizeObserver,
    onResize: ({ height }) => {
      if (isIframe) {
        // `theme.space[n]` is a string with the rem unit, so we have to `parseFloat` it and
        // multiply with 16 to get the pixel value.
        const heightWithPadding =
          height +
          (additionalSpace
            ? parseFloat(theme.space[additionalSpace]) * 16 * 2
            : 0) +
          (typeof customHeightChange === 'number'
            ? customHeightChange
            : HEIGHT_CHANGE_SAFETY_MARGIN);

        const customEvent = new CustomEvent('formwizardHeightChange', {
          detail: heightWithPadding,
        });

        // Send event
        if (initial) {
          // Brain dead solution to "ensure" the iframe event listener is initialized already
          // so the first update event does not get lost.
          // We will convert the FW to npm package soon so this become deprecated.
          setTimeout(() => {
            window.dispatchEvent(customEvent);
          }, 1000);
        } else {
          window.dispatchEvent(customEvent);
        }

        // Set flag that the function was running at least one time
        initial = false;

        // Send a post message to our embedding host, when we resize
        window.parent.postMessage(
          {
            type: 'resize',
            target: '#hausgold-wizard',
            height: `${heightWithPadding}px`,
          },
          '*'
        );
      }
    },
  });

  return { ref: observe };
};

export default useFormwizardHeightChange;

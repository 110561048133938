import {
  AspectRatio,
  Box,
  Card,
  Flex,
  SkeletonCircle,
  SkeletonText,
  Text,
} from '@hausgold/designsystem';
import { CheckCircled } from '@hausgold/designsystem/icons';
import { kebabCase } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import DynamicIcon from '../../Functional/DynamicIcon';

const CustomWizardCard = ({
  className,
  iconId,
  iconSvg,
  title,
  isActive,
  isLoading,
  children,
  ...props
}) => (
  <Card
    borderColor={isActive ? 'darkRed.400' : undefined}
    borderRadius="4px"
    boxShadow="md"
    className="wizard-card"
    data-is-selected={isActive}
    height="100%"
    position="relative"
    textAlign="center"
    transition="200ms box-shadow"
    wordBreak="break-word"
    p={{ base: 2, sm: 4 }}
    pb={{ base: 5, sm: 7 }}
    _hover={{
      cursor: 'pointer',
      borderColor: 'darkRed.400',
    }}
    {...props}
  >
    <CheckCircled
      boxSize={4}
      color="green.400"
      display={!isActive && 'none'}
      position="absolute"
      right="7%"
      top="7%"
    />
    {children || (
      <Fragment>
        <Box width={{ base: '75%', sm: '100%' }} mx="auto">
          {isLoading ? (
            <Box py=".5rem">
              <AspectRatio ratio={1 / 1}>
                <SkeletonCircle height="auto" speed={2} />
              </AspectRatio>
            </Box>
          ) : (
            iconSvg && (
              <DynamicIcon
                iconSvg={iconSvg}
                h="auto"
                w="100%"
                color="blue.400"
                mx="auto"
                className={`wizard-card-icon wizard-card-icon-${kebabCase(
                  iconId
                )}`}
              />
            )
          )}
        </Box>
      </Fragment>
    )}
    {(title || isLoading) && (
      <Box w="100%" my="auto">
        {isLoading ? (
          <SkeletonText
            endColor="blue.300"
            noOfLines={1}
            speed={2}
            skeletonHeight="19px"
            px={8}
            py="18px" // Quick fix to visually center vertically as Skeleton doesn't work within flex
          />
        ) : (
          <Flex alignItems="center" justifyContent="center" p={2} mb={0}>
            <Text
              as="span"
              lineHeight={1.4}
              color="gray.400"
              className="wizard-card-title"
              mb={0}
              fontWeight="normal"
            >
              {title && title}
            </Text>
          </Flex>
        )}
      </Box>
    )}
  </Card>
);

CustomWizardCard.propTypes = {
  children: PropTypes.node,
  iconId: PropTypes.string,
  iconSvg: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isActive: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};

CustomWizardCard.defaultProps = {
  children: null,
  iconId: null,
  iconSvg: null,
  title: null,
  isActive: false,
  isLoading: false,
  className: '',
};

export default CustomWizardCard;

import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Feedback, Flex, Box, Text } from '@hausgold/designsystem';
import { ErrorMessage, useField } from 'formik';
import {
  Slider as ChakraSlider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
} from '@chakra-ui/react';
import Default from './Default';

/**
 * Handles numerical slider inputs.
 * @param stepKey
 * @param lineKey
 * @param unit
 * @param placeholder
 * @param mandatory
 * @param options
 * @returns {JSX.Element}
 * @constructor
 */
const Slider = ({
  stepKey,
  lineKey,
  unit,
  placeholder,
  mandatory,
  options,
  min,
  max,
  stepSize,
  defaultValue,
}) => {
  const [, meta, helper] = useField(stepKey);

  const [rangeValue, setRangeValue] = useState(defaultValue);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleRangeChange = (value) => {
    setRangeValue(value);
    helper.setValue(value);
  };

  useEffect(() => {
    // Only update after input is touched.
    // Otherwise, the initial call would prevent the slider from start at the specified defaultValue.
    if (meta.touched) setRangeValue(meta.value);
  }, [meta.value, meta.touched]);

  return (
    <Fragment>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Flex
          w={{ base: '90%', sm: '80%', md: '60%' }}
          maxW="500px"
          mb={8}
          flexWrap={{ base: 'wrap', sm: 'nowrap' }}
          justifyContent="space-between"
        >
          <Box mr={4} order={1}>
            {min}&nbsp;{unit}
          </Box>
          <ChakraSlider
            value={rangeValue}
            min={min}
            max={max}
            step={stepSize}
            onChange={handleRangeChange}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            focusThumbOnChange={false}
            size="lg"
            order={2}
          >
            <SliderTrack>
              <Box position="relative" right={10} />
              <SliderFilledTrack />
            </SliderTrack>
            <Tooltip
              hasArrow
              bg="blue.400"
              color="white"
              placement="top"
              isOpen={showTooltip}
              label={`${rangeValue} ${unit ?? ''}`}
            >
              <SliderThumb boxSize={6} />
            </Tooltip>
          </ChakraSlider>
          <Box ml={4} order={{ base: 1, sm: 3 }}>
            {max}&nbsp;{unit}
          </Box>
        </Flex>

        <div>
          <Default
            type="text"
            onKeyDown={(event) => {
              if (
                !/[0-9]/.test(event.key) &&
                event.key !== 'Backspace' &&
                event.key !== 'Delete' &&
                // Allow hotkeys (CTRL+C, CTRL+V)
                !event.ctrlKey &&
                !event.altKey &&
                // Allow mac's hotkeys (CMD+C, CMD+V)
                !event.metaKey &&
                // Allow left and right arrow keys
                event.key !== 'ArrowLeft' &&
                event.key !== 'ArrowRight'
              ) {
                event.preventDefault();
              }
            }}
            inputMode="numeric"
            name={stepKey}
            unit={unit}
            {...options}
            stepKey={stepKey}
            lineKey={lineKey}
            placeholder={`${placeholder || ''} ${mandatory ? '*' : ''}`}
          />

          <Flex flexDirection="column" mt={2} justifyContent="space-between">
            <ErrorMessage name={stepKey}>
              {(message) => (
                <Feedback mb={0} mx="auto" display="block">
                  {message}
                </Feedback>
              )}
            </ErrorMessage>
            <Text
              as="div"
              w="100%"
              textAlign={{ base: 'inherit', md: 'center' }}
              color="gray.400"
              mb={0}
            >
              Geben Sie, falls bekannt, den exakten Wert ein.
            </Text>
            {mandatory && !(meta.touched && meta.error) && (
              <Text as="span" ml="auto" color="gray.400" mb={0}>
                * Pflichtfeld
              </Text>
            )}
          </Flex>
        </div>
      </Flex>
    </Fragment>
  );
};

Slider.propTypes = {
  mandatory: PropTypes.bool,
  placeholder: PropTypes.string,
  unit: PropTypes.string,
  stepKey: PropTypes.string.isRequired,
  lineKey: PropTypes.string.isRequired,
  options: PropTypes.object,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  stepSize: PropTypes.number,
  defaultValue: PropTypes.number.isRequired,
};

Slider.defaultProps = {
  unit: null,
  placeholder: null,
  mandatory: false,
  options: {},
  stepSize: 1,
};

export default Slider;

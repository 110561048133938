/* eslint-disable no-param-reassign */
import * as Yup from 'yup';
import PhoneNumber from 'awesome-phonenumber';

const isValidCountryCode = (countryCode) =>
  typeof countryCode === 'string' && countryCode.length === 2;

Yup.addMethod(
  Yup.string,
  'phone',
  function phone(countryCode, strict, message) {
    return this.test('phone', message, (value) => {
      if (!isValidCountryCode(countryCode)) {
        countryCode = 'DE';
        strict = false;
      }

      try {
        const pn = new PhoneNumber(value, countryCode);

        if (!pn.isPossible()) {
          return false;
        }

        return pn.isValid();
      } catch (error) {
        // Catch errors from google-libphonenumber and return false - because if
        // google's library throws errors, it's not a valid phonenumber and we
        // don't want that stuff in our console! :-)
        return false;
      }
    });
  }
);

import {
  Col,
  Row,
  Box,
  SkeletonText,
  useBreakpoint,
} from '@hausgold/designsystem';
import { times } from 'lodash-es';
import React, { Fragment, useContext } from 'react';
import { WizardContext } from 'fw-utils/contexts';
import FluidMaxContainer from '../components/FluidMaxContainer';
import WizardCard from '../components/WizardCard';

const Skeleton = () => {
  const {
    components: {
      WizardHeading,
      StartLayoutContainer,
      CustomNavigationButtonsStart,
    },
  } = useContext(WizardContext);

  const isBiggerThanSM = useBreakpoint('sm', '>');

  return (
    <Fragment>
      <WizardHeading>
        <SkeletonText
          noOfLines={1}
          speed={2}
          px={6}
          /*
           * Height is same like <HeadingH1> (see WizardHeading.jsx)
           * 1.5 rem (24 px like fontsize) + 1 extra pixel the normal headline get because of line-height = 25px)
           * In case there are multiple skeleton lines the space between them needs to be taken into account
           * so the overall height remain the same.
           * Issue: https://github.com/hausgold/designsystem/issues/370
           */
          skeletonHeight={['23px', '25px']}
          endColor="blue.300"
        />
        {
          /*
           * On smaller screens the headline will take 2 rows probably so we add another SkeletonText here.
           * Note: We do not use one SkeletonText with noOfLines={2}
           * because the styles which needs to be adjusted in that case are way more complex
           */
          !isBiggerThanSM && (
            <SkeletonText
              noOfLines={1}
              speed={2}
              px={6}
              // distance to upper SkeletonText to prevent touching each other
              mt={1}
              /*
               * Height is same like <HeadingH1> (see WizardHeading.jsx)
               * 1.5 rem (24 px like fontsize) - 1 pixel from mt to prevent touching each other
               * but remain the overall height the normal text would have.
               */
              skeletonHeight="23px"
              endColor="blue.300"
            />
          )
        }
      </WizardHeading>
      <FluidMaxContainer>
        <StartLayoutContainer>
          <Row>
            {times(4, (index) => (
              <Col mb={4} key={index} width={[1 / 2, 1 / 4]}>
                <WizardCard isLoading />
              </Col>
            ))}
          </Row>
          <Box
            /*
             * Adds `CustomNavigationButtonsStart` here to already allocate the needed space to prevent CLS.
             * As we only need the space here and not its content the visibility is hidden.
             */
            visibility="hidden"
          >
            {CustomNavigationButtonsStart && <CustomNavigationButtonsStart />}
          </Box>
        </StartLayoutContainer>
      </FluidMaxContainer>
    </Fragment>
  );
};

export default Skeleton;

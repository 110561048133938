import { useRouter } from 'next/router';
import { useMemo } from 'react';

/**
 * `recruiterId` retrieval logic
 * @return {string,null}
 */
const useRecruiterId = () => {
  const { query } = useRouter();

  return useMemo(() => query.recruiterId, [query]);
};

export default useRecruiterId;

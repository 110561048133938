import { Button, Flex, HeadingH5 } from '@hausgold/designsystem';
import { ArrowRightDrop } from '@hausgold/designsystem/icons';
import FluidMaxContainer from 'fw-components/FluidMaxContainer';
import RendererLayoutContainer from 'fw-components/Layouts/RendererLayoutContainer';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Offers the user the option to go into the "extended wizard" or skip and go directly to VP.
 * @param handleRedirect
 * @param handleGoForward
 * @returns {JSX.Element}
 * @constructor
 */
const ExtendedStart = ({ handleRedirect, handleGoForward }) => (
  <FluidMaxContainer>
    <RendererLayoutContainer>
      <HeadingH5
        fontWeight="semibold"
        fontSize={{ base: 'xl', md: '2xl' }}
        mx={{ base: 0, md: 10 }}
        mb={6}
        textAlign="center"
      >
        Für die kostenlose Immobilienbewertung vor Ort ermitteln wir für Sie den
        passenden Immobilienexperten.
        <br />
        Dazu benötigen wir noch einige Daten.
      </HeadingH5>
      <Flex flexDirection="column" alignItems="center">
        <Button
          id="button--extended-continue"
          variant="primary"
          mb={1}
          onClick={() => {
            handleGoForward('weiter');
          }}
          width="156px"
        >
          Weiter
          <ArrowRightDrop boxSize={2.5} ml={{ base: 1.5, md: 2.5 }} />
        </Button>
        <Button
          id="button--extended-skip"
          variant="ghost"
          as="a"
          borderRadius="circle"
          width="156px"
          onClick={handleRedirect}
        >
          Überspringen
        </Button>
      </Flex>
    </RendererLayoutContainer>
  </FluidMaxContainer>
);

export default ExtendedStart;

ExtendedStart.propTypes = {
  handleRedirect: PropTypes.func.isRequired,
  handleGoForward: PropTypes.func.isRequired,
};
